import { required, confirmed, length, email, max, max_value, min_value, integer, oneOf } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match"
});

extend("length", {
  ...length,
  message: "This field must have 2 options"
});

extend("max", {
  ...max,
  message: "This field must have a maximum of {length} characters"
})

extend("max_value", {
  ...max_value,
  message: "This field must have a maximum of {max}"
})

extend("min_value", {
  ...min_value,
  message: "This field must have a minimum of {min}"
})

extend("integer", {
  ...integer,
  message: "This field must be an integer"
})

extend("oneOf", {
  ...oneOf,
  message: "This field must be one of the allowed values"
})

extend('multipleOf100', {
  validate(value) {
    return value % 100 === 0;
  },
  message: 'The value must be a multiple of 100'
});