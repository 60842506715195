<template>
  <div>
    <div class="content">
    </div>
    <b-table
      class="vertical-aligned" 
      :data="leaderboard"
      hoverable
      bordered
      narrowed
      striped
      :loading="isLoading"
    >
      <b-table-column field="score" centered label="Score" v-slot="props">
        {{ props.row.score ? formatNumber(props.row.score) : '- - - ' }}
      </b-table-column>
      <b-table-column label="User" v-slot="props">
        <div class="is-flex is-flex-direction-row is-align-items-center" >
          <figure class="image is-32x32 mr-3" >
            <img class="is-rounded" :src="props.row.user.profile_picture" alt="avatar" width="32" height="32" v-if="props.row.user && props.row.user.profile_picture">
            <!-- black circle -->
            <div class="is-rounded" style="width: 32px; height: 32px; background-color: #CACACA; border-radius: 32px" v-else></div>
          </figure>
          <div v-if="props.row.user">
            <p class="has-text-weight-bold">{{ props.row.user.name }} <a @click="openUserModal(props.row.user.id)"><i class="fas fa-magnifying-glass is-size-7 fa-fw"></i></a></p>
            <p class="is-size-7 is-family-monospace">ID: {{ props.row.user.id }}</p>
          </div>
          <div v-else>
            <p class="is-italic">No user or no data</p>
            <p class="is-size-7 is-family-monospace">ID: --------</p>
          </div>
        </div>
      </b-table-column>
      <b-table-column field="receipt" centered label="Receipt" v-slot="props">
        <span class="is-size-7 is-family-monospace">{{ props.row.receipt || '- - -' }}</span>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered" v-if="!isLoading">No records</div>
        <div class="has-text-centered" v-else>Loading...</div>
      </template>
    </b-table>
    <div class="buttons is-centered">
      <button class="button is-dark is-outlined" @click="loadMoreData" :disabled="isLoading">Load more</button>
    </div>
  </div>
</template>

<script>
import ModalUserData from '@/components/ModalUserData.vue'

export default {
  name: 'InstanceStandingsLeaderboardTable',
  props: {
    idTournament: {
      type: String,
      required: true
    },
    idInstance: {
      type: String,
      required: true
    },
    tournamentInstance: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      leaderboard: [],
      errorMessage: '',
      isLoading: true,
      page: 1,
      limit: 20,
      totalItems: 0
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    getInitialData() {
      this.isLoading = true;
      this.$http.get(`/tournaments/${this.idTournament}/instances/${this.idInstance}/leaderboard`, {
        params: {
          page: this.page,
          limit: this.limit
        }
      })
      .then(response => {
        const leaderboard = response.data.leaderboard;
        this.leaderboard = leaderboard;
        this.isLoading = false;
      })
      .catch(error => {
        this.showErrorNotification('There was an error loading the instance leaderboard. Please try again later.');
        this.errorMessage = error.response.data.message;
        this.isLoading = false;
        console.error(error);
      })
    },
    loadMoreData(){
      this.page++;
      this.isLoading = true;
      this.$http.get(`/tournaments/${this.idTournament}/instances/${this.idInstance}/leaderboard`, {
        params: {
          page: this.page,
          limit: this.limit
        }
      })
      .then(response => {
        const leaderboard = response.data.leaderboard;
        this.leaderboard = this.leaderboard.concat(leaderboard);
        this.isLoading = false;
      })
      .catch(error => {
        this.showErrorNotification('There was an error loading the instance leaderboard. Please try again later.');
        this.errorMessage = error.response.data.message;
        this.isLoading = false;
        console.error(error);
      })
    },
    openUserModal(userId) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalUserData,
        trapFocus: true,
        props: {
          userId
        },
      })
    }
  }
}
</script>