import axios from 'axios'
import store from '../store'
// import { SnackbarProgrammatic as Snackbar } from 'buefy'

// if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_MAKE_PROXY === 'true') {
//   axios.defaults.baseURL = '/apiconops/'
// } else {
  // axios.defaults.baseURL = process.env.VUE_APP_API_URL
// }
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.withCredentials = true
axios.defaults.timeout = 60000

// let token = store.getters.getToken
// if (token) {
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`
// }

// // if request is a POST, add "token" in the body of the request
// axios.interceptors.request.use(
//   config => {
//     if (config.method === 'post' || config.method === 'put') {
//       config.data = {
//         ...config.data
//       }
//       // getToken from getter from store
//       if(store.getters.getToken) {
//         config.data.token = store.getters.getToken
//       }
//     }
//     return config
//   },
//   error => Promise.reject(error)
// )

axios.interceptors.response.use(
  response => {
    // if response is a 200 and has a data data.error == 'NOT_AUTHORIZED', logout user
    if (response.status === 200 && response.data.error === 'NOT_AUTHORIZED') {
      store.dispatch('logout')
    }
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
    }
    return Promise.reject(error)
  }
)


export default axios
