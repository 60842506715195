<template>
  <div>
    <h1 class="title is-4"><i class="fas fa-file-alt"></i>&nbsp;Tournament Instance Definition</h1>
    <div class="columns">
      <div class="column">
        <table class="table is-narrow is-bordered is-fullwidth is-striped">
          <tbody>
            <tr>
              <th>Tournament Name</th>
              <td>{{ tournamentInstance.readableName }}</td>
            </tr>
            <tr>
              <th>Instance ID</th>
              <td>{{ tournamentInstance.instanceId }}</td>
            </tr>
            <tr>
              <th>Round Number</th>
              <td>{{ tournamentInstance.roundNumber }}</td>
            </tr>
            <tr>
              <th>Number of entrants</th>
              <td>{{ tournamentInstance.numEntrants }}</td>
            </tr>
            <tr>
              <th>Score at Threshold</th>
              <td>{{ tournamentInstance.scoreAtThreshold }}</td>
            </tr>
            <tr>
              <th>Start date & time (Instance)</th>
              <td>{{ tournamentInstance.startTimestamp }} (Asia/Manila)</td>
            </tr>
            <tr>
              <th>End date & time (Instance)</th>
              <td>{{ tournamentInstance.endTimestamp }} (Asia/Manila)</td>
            </tr>
            <tr>
              <th>Country Blacklisted</th>
              <td>{{ getCountryList(tournamentInstance.countryBlackList) }}</td>
            </tr>
            <tr>
              <th>Country WhiteList</th>
              <td>{{ getCountryList(tournamentInstance.countryWhiteList) }}</td>
            </tr>
            <tr>
              <th>Entry Cost</th>
              <td>{{ tournamentInstance.entryCost }}</td>
            </tr>
            <tr>
              <td colspan="2" class="is-size-7 has-text-grey">IMPORTANT: The cost is the value times 100. Ex.: If the entry cost is 5 coins, the value to insert is 5 * 100 = 500. (The value must be a multiple of 100)</td>
            </tr>
            <tr>
              <th>Entry Currency</th>
              <td>{{ getEntryCurrency(tournamentInstance.entryCurrency) }}</td>
            </tr>
            <tr>
              <th>Is entry Free only once</th>
              <!-- 0 is No, 1 is Yes -->
              <td>{{ tournamentInstance.isEntryFreeOnce ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Player MAX Level</th>
              <td>{{ tournamentInstance.playerMaxLevel }}</td>
            </tr>
            <tr>
              <th>Player MIN Level</th>
              <td>{{ tournamentInstance.playerMinLevel }}</td>
            </tr>
            <tr>
              <th>Player MAX Race</th>
              <td>{{ tournamentInstance.playerMaxRace }}</td>
            </tr>
            <tr>
              <th>Player MIN Race</th>
              <td>{{ tournamentInstance.playerMinRace }}</td>
            </tr>
            <tr>
              <th>Bike MAX Level</th>
              <td>{{ tournamentInstance.bikeMaxLevel }}</td>
            </tr>
            <tr>
              <th>Bike MIN Level</th>
              <td>{{ tournamentInstance.bikeMinLevel }}</td>
            </tr>
            <tr>
              <th>Player starting position</th>
              <td>{{ tournamentInstance.playerStartingPosition }}</td>
            </tr>
            <tr>
              <td colspan="2" class="is-size-7 has-text-grey">Min: 0 / Max: 32</td>
            </tr>
            <tr>
              <th>Opponents Limit</th>
              <td>{{ getOpponentsLimit(tournamentInstance.opponentsLimit) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="is-size-7 has-text-grey">Min: -1 / Max: 31</td>
            </tr>
            <tr>
              <th>Score Display</th>
              <td>{{ getScoreDisplay(tournamentInstance.scoreDisplay) }}</td>
            </tr>
            <tr>
              <th>Winning Condition</th>
              <td>{{ getWinningCondition(tournamentInstance.winningCondition) }}</td>
            </tr>
            <tr>
              <th>Sort Order</th>
              <td>{{ tournamentInstance.sortOrder }}</td>
            </tr>
            <tr>
              <th>Worst Division</th>
              <td>{{ tournamentInstance.minIAP }}</td>
            </tr>
            <tr>
              <th>Worst Division</th>
              <td>{{ tournamentInstance.worstDivision }}</td>
            </tr>
            <tr>
              <th>Best Division</th>
              <td>{{ tournamentInstance.bestDivision }}</td>
            </tr>
            <tr>
              <th>Bike Restriction</th>
              <td>{{ getBikeRestriction(tournamentInstance.bikeRestriction) }}</td>
            </tr>
            <tr>
              <th>Bike Provided (ID)</th>
              <td>{{ tournamentInstance.bikeProvided }}</td>
            </tr>
            <tr>
              <th>Is bike upgrades allowed?</th>
              <td>{{ tournamentInstance.bikeUpgradesAllowed ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Is compulsives allowed?</th>
              <td>{{ tournamentInstance.compulsivesAllowed ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Lap Limit</th>
              <td>{{ tournamentInstance.lapLimit }}</td>
            </tr>
            <tr>
              <th>Circuit Restriction</th>
              <td>{{ getCircuitRestriction(tournamentInstance.circuitRestriction) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column">
        <h1 class="title is-5">Prize 1</h1>
        <table class="table is-narrow is-bordered is-fullwidth is-striped">
          <tbody>
            <tr>
              <th>Prize Class 1 ID</th>
              <td>{{ getPrizeId(tournamentInstance.prizeClass1ID) }}</td>
            </tr>
            <tr>
              <th>Prize Class 1 Number</th>
              <td>{{ tournamentInstance.prizeClass1Number }}</td>
            </tr>
            <tr>
              <th>Prize Class 1 Rank</th>
              <td>{{ tournamentInstance.prizeClass1Rank }}</td>
            </tr>
            <tr>
              <th>Prize Class 1 URL</th>
              <td>{{ tournamentInstance.prizeClass1URL }}</td>
            </tr>
            <tr>
              <th>Prize Class 1 Desc</th>
              <td>{{ tournamentInstance.prizeClass1Desc }}</td>
            </tr>
          </tbody>
        </table>
        <h1 class="title is-5">Prize 2</h1>
        <table class="table is-narrow is-bordered is-fullwidth is-striped">
          <tbody>
            <tr>
              <th>Prize Class 2 ID</th>
              <td>{{ getPrizeId(tournamentInstance.prizeClass2ID) }}</td>
            </tr>
            <tr>
              <th>Prize Class 2 Number</th>
              <td>{{ tournamentInstance.prizeClass2Number }}</td>
            </tr>
            <tr>
              <th>Prize Class 2 Rank</th>
              <td>{{ tournamentInstance.prizeClass2Rank }}</td>
            </tr>
            <tr>
              <th>Prize Class 2 URL</th>
              <td>{{ tournamentInstance.prizeClass2URL }}</td>
            </tr>
            <tr>
              <th>Prize Class 2 Desc</th>
              <td>{{ tournamentInstance.prizeClass2Desc }}</td>
            </tr>
          </tbody>
        </table>
        <h1 class="title is-5">Prize 3</h1>
        <table class="table is-narrow is-bordered is-fullwidth is-striped">
          <tbody>
            <tr>
              <th>Prize Class 3 ID</th>
              <td>{{ getPrizeId(tournamentInstance.prizeClass3ID) }}</td>
            </tr>
            <tr>
              <th>Prize Class 3 Number</th>
              <td>{{ tournamentInstance.prizeClass3Number }}</td>
            </tr>
            <tr>
              <th>Prize Class 3 Rank</th>
              <td>{{ tournamentInstance.prizeClass3Rank }}</td>
            </tr>
            <tr>
              <th>Prize Class 3 URL</th>
              <td>{{ tournamentInstance.prizeClass3URL }}</td>
            </tr>
            <tr>
              <th>Prize Class 3 Desc</th>
              <td>{{ tournamentInstance.prizeClass3Desc }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { countries } from "@/data/countries.js";

export default {
  name: 'TournamentInstanceData',
  props: {
    tournamentInstance: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCountryList(countryList){
      if(countryList == '') return '-'
      if(countryList == 'ALL') return 'All countries'
      // the list comes like in this format: DE|IT|FR|ES|PT|BE
      // we need to convert it to this format: Germany, Italy, France, Spain, Portugal, Belgium
      let countriesList = countryList.split('|')
      let countriesNames = []
      countriesList.forEach(country => {
        countriesNames.push(countries.find(c => c.code === country).name)
      })
      return countriesNames.join(', ')
    },
    getEntryCurrency(entryCurrency) {
      switch(entryCurrency) {
        case 1:
          return 'Coins'
        case 2:
          return 'Diamonds'
        case 3:
          return 'Dollars'
        case 4:
          return 'Race Tickets'
        default:
          return `Unknown (${entryCurrency})`
      }
    },
    getPrizeId(prizeId) {
      switch(prizeId) {
        case 0:
          return 'No prize'
        case 1:
          return 'Coins'
        case 2:
          return 'Diamonds'
        case 3:
          return 'Bike'
        case 4:
          return 'Physical Prize'
        default:
          return `Unknown (${prizeId})`
      }
    },
    getWinningCondition(winningCondition) {
      switch(winningCondition){
        case 1:
          return 'High Score'
        case 2:
          return 'Accumulated Score'
        case 3:
          return 'Total Circuit Score'
        case 4:
          return 'Best Daily Score'
        case 5:
          return 'Lottery Tickets'
        default:
          return `Unknown (${winningCondition})`
      }
    },
    getScoreDisplay(scoreDisplay) {
      switch(scoreDisplay) {
        case 0:
          return 'Don\'t display'
        case 1:
          return 'Display Score'
        case 2:
          return 'Display Percent Increase'
        default:
          return `Unknown (${scoreDisplay})`
      }
    },
    getOpponentsLimit(opponentsLimit){
      switch(opponentsLimit){
        case -1:
         return `No limit (${opponentsLimit})`
        default:
          return opponentsLimit
      }
    },
    getBikeRestriction(bikeRestriction){
      switch(bikeRestriction){
        case 'NONE':
          return 'None'
        case 'SELECT':
          return 'Select bikes'
        case 'OPEN':
          return 'Open'
        case 'TEAM':
          return 'Team'
        case 'PRO TEAM':
          return 'Pro Team'
        case 'PROVIDED':
          return 'Provided'
        default:
          return `Unknown (${bikeRestriction})`
      }
    },
    getCircuitRestriction(circuitRestriction) {
      // <option value="NONE">None</option>
      // <option value="RANDOM">Random</option>
      // <option value="LIMITED">Limited (Choose circuits...)</option>
      switch(circuitRestriction){
        case 'NONE':
          return 'None'
        case 'RANDOM':
          return 'Random'
        case 'LIMITED':
          return `Limited: ${circuitRestriction}`
        default:
          return `Unknown (${circuitRestriction})`
      }
    }
  }
}
</script>
