<template>
  <section class="hero is-white is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered is-mobile">
          <div class="column is-6-desktop is-8-tablet is-12-mobile">
            <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
              <div>
                <h1 class="subtitle is-6 has-text-grey">
                  MotoGP Tournament Admin
                </h1>
                <h1 class="title is-4" >
                  Welcome!
                </h1>
              </div>
              <div class="is-size-2 mr-4">
                👋
              </div>
            </div>
            <div v-if="userRole === 'admin'">
              <hr>
              <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div>
                  <h5 class="title is-6"><i class="fas fa-users"></i> Users management</h5>
                  <h6 class="subtitle is-7 is-italic has-text-grey">Create and manage users for the Admin API</h6>
                </div>
                <ul class="has-text-right is-size-7 options">
                  <li>
                    <router-link :to="{name: 'users'}" class="has-text-link">
                      Go to Users <i class="fas fa-arrow-right"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="['admin', 'user'].includes(userRole)">
              <hr>
              <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div>
                  <h5 class="title is-6"><i class="fas fa-trophy"></i> Tournament management</h5>
                  <h6 class="subtitle is-7 is-italic has-text-grey">Create or edit tournaments. Search and enable/disable tournaments.</h6>
                </div>
                <ul class="has-text-right is-size-7 options">
                  <li>
                    <router-link :to="{name: 'tournaments'}" class="has-text-link">
                      Go to Tournaments <i class="fas fa-arrow-right"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="['admin', 'reporter', 'user'].includes(userRole)">
              <hr>
              <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                <div>
                  <h5 class="title is-6"><i class="fas fa-line-chart"></i> Reports</h5>
                  <h6 class="subtitle is-7 is-italic has-text-grey">Check the reports for the tournaments, with insights about engagement, currency spent and instances overview.</h6>
                </div>
                <ul class="has-text-right is-size-7 options">
                  <li>
                    <router-link :to="{name: 'reports-tournaments-currency-spent'}" class="has-text-link">
                      Engagement&nbsp;<i class="fas fa-arrow-right"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'reports-tournaments-currency-spent'}" class="has-text-link">
                      Currency Spent&nbsp;<i class="fas fa-arrow-right"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'reports-tournaments-currency-spent'}" class="has-text-link">
                      Instances Overview&nbsp;<i class="fas fa-arrow-right"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <hr>
            <p class="has-text-grey is-size-7">Last update: April 9th 2024 - v2.0</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeComponent',
  computed: {
    userRole(){
      return this.$store.getters.getLoggedUserRole;
    }
  }
};
</script>

<style lang="scss" scoped>
.options li {
  text-wrap: nowrap;
  margin-left: 1rem;
}
</style>