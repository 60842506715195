import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import router from './router'
import store from './store'
import http from './http';
import baseUtils from './mixins/baseUtils';
import "./vee-validate"

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDialogConfirmText: 'OK',
  defaultDialogCancelText: 'Cancelar',
  defaultDayNames: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  defaultMonthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
});

Vue.mixin(baseUtils);

Vue.prototype.$http = http;

Vue.config.productionTip = false

http.get('/user/checklogin')
  .then(response => {
  // if response is 200, then we are logged in
  if (response.status === 200) {
    // store.dispatch('login', response.data)
    store.commit('setLoggedUser', response.data)
      console.log('✅ Logged in')
    }
  })
  .then(() => {
    http.get('/stringtable/list')
      .then(response => {
        store.commit('setStrings', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  })
  .catch(error => {
    // if response is 401, then we are not logged in
    if (error.response.status === 401) {
      // store.disaptch('logout')
      console.log('❌ Not logged in')
    }
  })
  .finally(() => {
    // define the vue app
    const vueApp = new Vue({
      router,
      store,
      http,
      render: h => h(App)
    })
    // mount the vue app!
    vueApp.$mount('#app')
  })


// new Vue({ 
//   router,
//   store,
//   http,
//   render: h => h(App)
// }).$mount('#app')
