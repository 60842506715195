<template>
  <div>
    <div class="content">
      <p class="">Total notifications sent: <span v-if="isLoading" class="is-italic">Loading...</span><span v-else><b>{{ notifications.length }}</b> notifications</span></p>
    </div>
    <b-table
      class="vertical-aligned" 
      :data="notifications"
      hoverable
      bordered
      narrowed
      striped
      detailed
      detail-key="id"
      :show-detail-icon="true"
      :loading="isLoading"
    >
      <b-table-column field="positionNumber" centered label="Position" v-slot="props">
        {{ props.row.position || '- - -' }}
      </b-table-column>
      <b-table-column label="User" v-slot="props">
        <div class="is-flex is-flex-direction-row is-align-items-center" >
          <figure class="image is-32x32 mr-3" >
            <img class="is-rounded" :src="props.row.user.profile_picture" alt="avatar" width="32" height="32" v-if="props.row.user && props.row.user.profile_picture">
            <!-- black circle -->
            <div class="is-rounded" style="width: 32px; height: 32px; background-color: #CACACA; border-radius: 32px" v-else></div>
          </figure>
          <div v-if="props.row.user">
            <p class="has-text-weight-bold">{{ props.row.user.name }} <a @click="openUserModal(props.row.user.id)"><i class="fas fa-magnifying-glass is-size-7 fa-fw"></i></a></p>
            <p class="is-size-7 is-family-monospace">ID: {{ props.row.user.id }}</p>
          </div>
          <div v-else>
            <p class="is-italic">No top user or no data</p>
            <p class="is-size-7 is-family-monospace">ID: --------</p>
          </div>
        </div>
      </b-table-column>
      <b-table-column field="receipt" label="Receipt ID" v-slot="props">
        <span class="is-size-7 is-family-monospace">{{ props.row.receipt }}</span>
      </b-table-column>
      <!-- Type Receipt -->
      <b-table-column field="typeReceipt" label="Receipt Type" v-slot="props">
        <span class="is-family-monospace">{{ props.row.typeReceipt || '-' }}</span>
      </b-table-column>
      <b-table-column field="usetMessage.is_read" label="Read" centered v-slot="props">
        <div v-if="props.row.userMessage.error">
          <i class="fas fa-trash fa-lg"></i>
        </div>
        <div v-else>
          <i class="fas fa-lg fa-envelope-open-text" v-if="props.row.userMessage.is_read" alt="Message read"></i>
          <i class="fas fa-lg fa-envelope" alt="" v-else></i>
        </div>
      </b-table-column>
      <template #detail="props">
        <div class="content" v-if="props.row.userMessage.error">
          <p class="has-text-danger">Error: {{ props.row.userMessage.error }}</p>
        </div>
        <div class="" v-else>
          <div class="columns">
            <div class="column">
              <h1 class="title is-5 mb-3 is-underlined">Message Data</h1>
              <table class="table is-bordered is-narrow is-fullwidth">
                <tbody>
                  <tr>
                  <th>Text</th>
                  <td><p class="is-size-7"><span class="is-family-monospace">{{ props.row.userMessage.message }}</span></p></td>
                  </tr>
                  <tr>
                  <th>Message ID</th>
                  <td><p class="is-size-7"><span class="is-family-monospace">{{ props.row.userMessage.id }}</span></p></td>
                  </tr>
                  <tr>
                  <th>Created At</th>
                  <td><p class="is-size-7">{{ props.row.userMessage.created_at }} (Asia/Manila)</p></td>
                  </tr>
                  <tr>
                  <th>Updated At</th>
                  <td><p class="is-size-7">{{ props.row.userMessage.created_at }} (Asia/Manila)</p></td>
                  </tr>
                  <tr>
                  <th>Type Receipt</th>
                  <td><p class="is-size-7">{{ props.row.userMessage.typeReceipt }}</p></td>
                  </tr>
                  <tr>
                  <th>Tournament Name</th>
                  <td><p class="is-size-7">{{ getString(props.row.userMessage.tournamentName) }}<br><span class="is-family-monospace">({{ props.row.userMessage.tournamentName}})</span></p></td>
                  </tr>
                  <tr>
                  <th>Prize Description<br><span class="is-size-7">or Tournament Name</span></th>
                  <td><p class="is-size-7">{{ props.row.userMessage.tournamentNameOrPrizeDescription }}</p></td>
                  </tr>
                  <tr>
                    <th>Message Status</th>
                    <td>
                      <!-- if is_read is true, print "Read" -->
                      <p class="is-size-7" v-if="props.row.userMessage.is_read">Read</p>
                      <!-- if is_read is false, print "Unread" -->
                      <p class="is-size-7" v-else>Unread</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="column">
              <h1 class="title is-5 mb-3 is-underlined">User Data</h1>
              <table class="table is-bordered is-narrow is-fullwidth">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td><p class="is-size-7">{{ props.row.user.name }} <a @click="openUserModal(props.row.user.id)"><i class="fas fa-magnifying-glass is-size-7 fa-fw"></i></a></p></td>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <td><p class="is-size-7"><span class="is-family-monospace">{{ props.row.user.id }}</span></p></td>
                  </tr>
                  <tr>
                    <th>Profile Picture</th>
                    <td>
                      <figure class="image is-32x32">
                        <img class="is-rounded" :src="props.row.user.profile_picture" alt="avatar" width="64" height="64" v-if="props.row.user.profile_picture">
                        <!-- black circle -->
                        <div class="is-rounded" style="width: 32px; height: 32px; background-color: #CACACA; border-radius: 32px" v-else></div>
                    </figure>
                    </td>
                  </tr>
                  <tr>
                    <th>Last Login</th>
                    <td><p class="is-size-7">{{ props.row.user.last_login }} (Asia/Manila)</p></td>
                  </tr>
                  <tr>
                    <th>Blacklisted</th>
                    <td><p class="is-size-7">{{ props.row.user.blacklisted ? 'Yes' : 'No' }}</p></td>
                  </tr>
                  <tr>
                    <th>Cheater</th>
                    <td><p class="is-size-7">{{ props.row.user.cheater ? 'Yes' : 'No' }}</p></td>
                  </tr>
                  <tr>
                    <th>Cheater Created At</th>
                    <td>
                      <p class="is-size-7" v-if="props.row.user.cheater_created_at">{{ props.row.user.cheater_created_at }}</p>
                      <p class="is-size-7" v-else>N/A</p>
                    
                    </td>
                  </tr>
                  <tr>
                    <th>Login Type</th>
                    <td><p class="is-size-7">{{ props.row.user.login }}</p></td>
                  </tr>
                </tbody> 
              </table>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="has-text-centered" v-if="!isLoading">No records</div>
        <div class="has-text-centered" v-else>Loading...</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ModalUserData from '@/components/ModalUserData.vue'

export default {
  name: 'InstanceStandingsNotificationsTable',
  props: {
    idTournament: {
      type: String,
      required: true
    },
    idInstance: {
      type: String,
      required: true
    },
    tournamentInstance: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      notifications: [],
      errorMessage: '',
      isLoading: true
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    getInitialData() {
      this.isLoading = true;
      this.$http.get(`/tournaments/${this.idTournament}/instances/${this.idInstance}/notifications`)
      .then(response => {
        const standings = response.data;
        this.notifications = standings;
        this.isLoading = false;
      })
      .catch(error => {
        this.showErrorNotification('There was an error loading the standings notifications. Please try again later.');
        this.errorMessage = error.response.data.message;
        this.isLoading = false;
        console.error(error);
      })
    },
    openUserModal(userId) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalUserData,
        trapFocus: true,
        props: {
          userId
        },
      })
    }
  }
}
</script>