<template>
  <div class="section">
    <div class="container">
      <h1 class="title is-3"><i class="fas fa-line-chart"></i>&nbsp;Tournament Engagement</h1>
      <hr>
      <h2 class="title is-4">Current active tournaments <span v-show="activeTournaments">({{ activeTournaments.length }})</span></h2>
      <p class="subtitle is-6">Here you can see the current active tournaments, with their respective information.</p>
      <b-field label="Timezone">
            <b-select v-model="timezone" :disabled="isLoading" expanded>
              <option value="local">Local ({{ myTimezone }})</option>
              <option value="philippines">Philippines (GMT +8)</option>
            </b-select>
          </b-field>
      <b-table :data="activeTournaments" :loading="isLoading" striped bordered hoverable>
        <!-- <b-table-column centered >
          <template v-slot:header="{}">
            <i class="fas fa-line-chart"></i>
          </template>
          <template v-slot="props">
            <a @click="selectTournament(props.row.tournamentId)"><i class="fas fa-line-chart"></i></a>
          </template>
        </b-table-column> -->
        <b-table-column sortable centered numeric label="ID" v-slot="props">
          {{ props.row.tournamentId }}
        </b-table-column>
        <b-table-column field="name" sortable label="Name" v-slot="props">
          <b-tooltip :label="props.row.name" position="is-right" v-if="props.row.name[0] !== '*'">
            <i class="fas fa-info-circle has-text-link fa-fw"></i>&nbsp;
          </b-tooltip>
          <span :class="props.row.name[0] !== '*' ? 'is-underlined' : 'is-italic'">{{ getString(props.row.name) ?? '❓ (STRING NOT FOUND)'}}</span>
        </b-table-column>
        <b-table-column field="startTimestamp" centered label="Start" v-slot="props">
          {{ toSelectedTimezone(props.row.startTimestamp) }}
        </b-table-column>
        <b-table-column field="endTimestamp" centered label="End" v-slot="props">
          {{ toSelectedTimezone(props.row.endTimestamp) }}
        </b-table-column>
        <b-table-column sortable centered numeric label="Round #" v-slot="props">
          <p class="is-size-5">{{ props.row.roundNumber }}</p>
        </b-table-column>
        <b-table-column sortable centered numeric label="Entries" v-slot="props">
          <p class="is-size-5">{{ props.row.numEntrants }}</p>
        </b-table-column>
        <b-table-column label="Countries" v-slot="props" width="400">
          <p class="is-size-7"><b>Whitelist</b>: {{ getCountryList(props.row.countryWhiteList) }}</p>
          <p class="is-size-7"><b>Blacklist</b>: {{ getCountryList(props.row.countryBlackList) }}</p>
        </b-table-column>
      </b-table>
      <hr>
      <h1 class="title is-4">Engagement per tournament</h1>
      <h2 class="subtitle is-6">
        Select a specific tournament to see more information about it.
      </h2>
      <!-- <div class="box">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Tournament</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="selectedTournament" @change="getTournamentData">
                    <option :value="null" disabled>- Select a tournament -</option>
                    <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament.id">
                      ID: {{tournament.id}} - {{ getString(tournament.name) ?? '❓ (STRING NOT FOUND)' }}{{ activeTournamentsIds.includes(`${tournament.id}`) ? ' - 🟢 Active' : '' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <SelectTournament class="mb-5" @select="tournamentSelected"/>

      <!-- chart placeholder -->
      <div class="" v-if="selectedTournament != null && showChart">
        <ReportChart :rounds="chartRounds" :entries="chartEntries" :key="`chart-${selectedTournament}`"/>
        <ReportStats :chartData="chartData" :isTournamentActive="isSelectedTournamentActive" :key="`stats-${selectedTournament}`"/>
      </div>
      <div class="message is-warning" v-if="showErrorMessage">
        <div class="message-body">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SelectTournament from '@/components/SelectTournament.vue'
import ReportChart from '@/components/reports/tournaments/ReportChart.vue';
import ReportStats from '@/components/reports/tournaments/ReportStats.vue';
import moment from 'moment-timezone';
import { countries } from "@/data/countries.js";

export default {
  components: {
    ReportChart,
    ReportStats,
    SelectTournament
  },
  data() {
    return {
      activeTournaments: [],
      tournaments: [],
      isLoading: false,
      selectedTournament: null,
      isLoadingChart: false,
      showChart: false,
      timezone: 'local',
      chartRounds: [],
      chartEntries: [],
      chartData: [],
      selectedTournamentStats: null,
      isSelectedTournamentActive: false,
      highestEntriesTournament: null,
      showErrorMessage: false,
      errorMessage: '',
    }
  },
  mounted() {
    this.getInitialData();
  },
  methods: {
    tournamentSelected(tournament){
      this.selectedTournament = tournament.id
      this.getTournamentData();
    },
    getInitialData() {
      this.isLoading = true;
      this.startLoading();
      const promises = [
        this.$http.get('/tournaments/active'),
        this.$http.get('/tournaments/list'),
      ]
      Promise.all(promises).then(responses => {
        this.activeTournaments = responses[0].data;
        this.tournaments = responses[1].data;
        // get the active tournament with the highest number of entries
        this.highestEntriesTournament = this.activeTournaments.reduce((prev, current) => (prev.numEntrants > current.numEntrants) ? prev : current)
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
        this.stopLoading();
      })
    },
    toSelectedTimezone(timestamp) {
      // the timestamp is in Phillipines timezone
      const fixtimestamp =  timestamp.split(' ')[0] + 'T' + timestamp.split(' ')[1] + '+08:00'
      let date = new Date(fixtimestamp)
      if(this.timezone === 'local'){
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      }
      if(this.timezone === 'philippines'){
        return moment(date).tz('Asia/Manila').format('YYYY-MM-DD HH:mm:ss');
      }
    },
    getCountryList(countryList){
      if(countryList == '') return '-'
      if(countryList == 'ALL') return 'All countries'
      // the list comes like in this format: DE|IT|FR|ES|PT|BE
      // we need to convert it to this format: Germany, Italy, France, Spain, Portugal, Belgium
      let countriesList = countryList.split('|')
      let countriesNames = []
      countriesList.forEach(country => {
        countriesNames.push(countries.find(c => c.code === country).name)
      })
      return countriesNames.join(', ')
    },
    getTournamentData() {
      // check if the selectedTournament (which is an ID) is in the activeTournaments array
      // if it is, then the tournament is active
      this.showChart = false
      this.showErrorMessage = false
      this.chartRounds = []
      this.chartEntries = []
      this.chartData = []
      this.isSelectedTournamentActive = this.activeTournaments.some(tournament => parseInt(tournament.tournamentId) === parseInt(this.selectedTournament))
      this.startLoading()
      this.$http.get(`tournaments/stats/${this.selectedTournament}`)
      .then(response => {
        const instancesData = response.data

        // in instancesData there are roundNumber & numEntrants (aside from startTimestamp and endTimestamp)

        // there are rounds with the same number, so we need to group them and accumulate the numEntrants


        const rounds = []
        const numEntrants = []

        instancesData.forEach(instance => {
          const roundIndex = rounds.indexOf(instance.roundNumber)
          if(roundIndex === -1){
            rounds.push(instance.roundNumber)
            numEntrants.push(instance.numEntrants)
          } else {
            numEntrants[roundIndex] += instance.numEntrants
          }
        })

        // let rounds = []
        // let entries = []
        // response.data.forEach(round => {
        //   rounds.push(round.roundNumber)
        //   entries.push(round.numEntrants)
        // })

        // chartRounds is the x-axis which is an array of round numbers
        this.chartRounds = rounds
        this.chartEntries = numEntrants
        this.chartData = instancesData
        this.showChart = true
      }).catch(error => {
        // if error is 404, the tournament has no stats yet
        if (error.response.status === 404) {
          this.showErrorNotification('No instances of this tournament found...')
          this.errorMessage = 'No instances of this tournament found...'
          this.showErrorMessage = true
        } else {
          this.showErrorNotification('Error fetching tournament stats...')
          this.errorMessage = 'Error fetching tournament stats...'
          this.showErrorMessage = true
        }
        console.error(error);
      })
      .finally(() => {
        this.stopLoading()
      })
    }
  },
  computed: {
    myTimezone: function () {
      return moment.tz.guess();
    },
    activeTournamentsIds: function () {
      if(this.activeTournaments.length == 0) return []
      return this.activeTournaments.map(tournament => tournament.tournamentId);
    }
  }
};
</script>