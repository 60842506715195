<template>
  <v-chart class="chart-rounded" :option="chartOptions" />
</template>

<script>
  import { use } from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { LineChart } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent
  } from "echarts/components";
  import VChart, { THEME_KEY } from "vue-echarts";

  use([
    CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent
  ]);
  
  export default {
    props: {
      rounds: {
        type: Array,
        required: true
      },
      entries: {
        type: Array,
        required: true
      }
    },
    components: {
      VChart
    },
    provide: {
      [THEME_KEY]: "dark"
    },
    data() {
      return {
        chartOptions: {
          backgroundColor: '#2c343c',
          title: {
            text: 'Entries per round',
            textStyle: {
              color: '#fff'
            },
            top: 15,
            left: '4%'
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {
                show: true,
                title: 'Guardar'
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              snap: true,
              label: {
                backgroundColor: '#42a883'
              }
            },
            formatter: function (params) {
              let text = `<div class="has-text-black">`
              text += `<p class="is-size-6 mb-2 has-text-black"><b>Round # ${params[0].axisValue}</b></p>`
              text += `<p class=""><i class="fas fa-users fa-lg fa-fw"></i>&nbsp;${params[0].data} entries</p>`
              text += `</div>`
              return text
            }
          },
          grid: {
            left: '4%',
            right: '7%',
            bottom: '80',
            top: '15%',
            containLabel: true
          },
          dataZoom: [
            {
              type: 'inside',
            },
            {
              type: 'slider',
              // xAxisIndex: 1,
              // yAxisIndex: [0,1],
              bottom: 20,
              labelFormatter: (value, valueStr) => {
                console.log(value, valueStr)
                // ValueStr is a date un YYYY-MM-DD HH:MM:SS format, between date and time add a new line
                if(valueStr.length > 10) {
                  return `${valueStr.slice(0, 10)}\n${valueStr.slice(11)}`
                } else {
                  return valueStr
                }
              }
            }
          ],
          xAxis: {
            name: 'Round #',
            type: 'category',
            data: this.rounds,
            nameLocation: 'center',
            nameGap: 30,
            nameTextStyle: {
              fontWeight: 800
            },
            splitLine: {
              show: true
            }
          },
          yAxis: {
            name: 'Entries',
            type: 'value',
            splitArea: {
              show: true
            },
            nameLocation: 'center',
            nameGap: -20,
            axisLabel:{
              nameGap: 10
            }
          },
          series: [
            {
              name: "Entries",
              data: this.entries,
              type: 'line',
              symbolSize: 6,
              symbol: 'circle',
              color: '#fff',
              lineStyle: {
                color: '#42a883'
              },
            }
          ]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.chart {
  height: 500px;
  width: 100%;
}
</style>