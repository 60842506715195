import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'
import router from '../router'
import {
  SnackbarProgrammatic as Snackbar
} from 'buefy'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    loggedUser: null,
    lastTimeUpdated: new Date().getTime(),
    strings: []
  },
  mutations: {
    lastTimeUpdated(state, lastTimeUpdated) {
      state.lastTimeUpdated = lastTimeUpdated
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setLoggedUser(state, loggedUser) {
      state.loggedUser = loggedUser;
    },
    setStrings(state, strings) {
      state.strings = strings;
    },
    logout(state) {
      state.loggedUser = null;
    }
  },
  actions: {
    logout({commit}) {
      commit('logout');
      commit('setLoading', false);
      // push to login page
      Snackbar.open({
        message: 'You have been logged out',
        type: 'is-warning',
        duration: 5000,
        queue: true
      });
      // check if we are in the login page
      router.replace('/login');
    }
  },
  getters: {
    loggedUser(state) {
      return state.loggedUser;
    },
    isLogged(state) {
      return state.loggedUser !== null;
    },
    getLoggedUserRole(state) {
      return state.loggedUser ? state.loggedUser.user_role : null;
    }
  },
  modules: {},
  // plugins: [new VuexPersistence({
  //   key: 'mgp-tournament-app',
  //   reducer: (state) => ({
  //     loggedUser: state.loggedUser
  //   }),
  // }).plugin]
})
