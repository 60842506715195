<template>
  <section class="hero is-light is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-3-fullhd is-4-desktop is-6-tablet">
            <div class="box has-text-centered">
              <h1 class="title is-4">
                <i class="fas fa-sign-in"></i>&nbsp; Sign in
              </h1>
              <h1 class="subtitle is-6">
                MotoGP Tournament Admin
              </h1>
              
              <form @submit.prevent="login">
                <div class="field has-text-white">
                  <!-- <label class="label ">Email</label> -->
                  <div class="control">
                    <input class="input" type="email" placeholder="email@company.com" required v-model="email" :disabled="isLoading">
                  </div>  
                </div>
                <div class="field has-text-white">
                  <!-- <label class="label">Password</label> -->
                  <div class="control">
                    <input class="input" type="password" placeholder="Your password" required v-model="password" :disabled="isLoading">
                  </div>
                </div>
                <button class="button is-primary is-fullwidth" :class="{'is-loading': isLoading}">Enter&nbsp;<i class="fas fa-paper-plane"></i></button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from '../store'

export default {
  data(){
    return {
      isLoading: false,
      email: '',
      password: ''
    }
  },
  methods: {
    login(){
      this.startLoading()
      this.isLoading = true
      this.$http.post('/user/login', {
        email: this.email,
        password: this.password
      })
      .then(response => {
        this.$store.commit('setLoggedUser', response.data)
        return this.reloadStringsPromise()
      })
      .then((response) => {
        this.$store.commit('setStrings', response.data)
        this.$router.push('/')
      })
      .catch(response => {
        console.log(response)
      })
      .finally(() => {
        this.stopLoading()
        this.isLoading = false
      })
    }
  },
  beforeRouteEnter: (to, from, next) => {
    if (store.state.loggedUser) {
      next('/')
    } else {
      next()
    }
  }
}
</script>