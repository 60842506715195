<template>
  <div class="box">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Tournament</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div class="select is-fullwidth" :class="{'is-loading': isLoading}">
              <select v-model="selectedTournament" @change="emitSelectedTournament" :disabled="isLoading">
                <option :value="null" disabled>{{isLoading ? 'Loading...' : '- Select a tournament -'}}</option>
                <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament">
                  ID: {{tournament.id}} - {{ tournament.readableName }}{{ tournament.active ? ' - 🟢 Active' : '' }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTournament',
  data() {
    return {
      isLoading: true,
      tournaments: [],
      activeTournaments: [],
      selectedTournament: null,
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    getInitialData() {
      this.isLoading = true;
      const promises = [
        this.$http.get('/tournaments/list'),
        this.$http.get('/tournaments/active'),
      ]
      Promise.all(promises).then(responses => {
        const tournaments = responses[0].data;
        const activeTournamentsIds = responses[1].data.map(tournament => parseInt(tournament.tournamentId));
        tournaments.forEach(tournament => {
          // for each tournament, add a property "readableName"
          tournament.readableName = this.getString(tournament.name);
          // for each tournament, add a property "active"
          tournament.active = activeTournamentsIds.includes(parseInt(tournament.id));
        });
        // sort by active = true and id
        this.tournaments = tournaments.sort((a, b) => {
          if (a.active && !b.active) return -1;
          if (!a.active && b.active) return 1;
          return a.id - b.id;
        });
        this.emitTournaments();
        this.emitActiveTournaments();
      }).catch(error => {
        this.showErrorNotification('Error loading tournaments...');
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
        this.finishedLoading();
      });
    },
    emitSelectedTournament() {
      this.$emit('select', this.selectedTournament);
    },
    emitTournaments() {
      this.$emit('fetchTournaments', this.tournaments);
    },
    emitActiveTournaments() {
      this.$emit('fetchActiveTournaments', this.activeTournaments);
    },
    finishedLoading() {
      this.$emit('finishedLoading');
    },
  }
}
</script>