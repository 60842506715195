<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="is-clearfix">
            <h1 class="title is-pulled-left"><router-link :to="{name: 'tournaments'}"><i class="fas fa-arrow-left"></i></router-link>&nbsp;New tournament</h1>
            <h1 class="title is-pulled-right"><i class="fas fa-plus"></i>&nbsp;<i class="fas fa-trophy"></i></h1>
          </div>
          <br>
          <div class="notification has-text-centered px-2" v-if="isLoading">
            <div class="block">
              <p class=""><i class="fas fa-spin fa-sync"></i></p>
              <p class="is-size-7">Loading</p>
            </div>
          </div>
          <div class="notification is-warning" v-else-if="!isLoading && cantRender">
            <!-- An error ocurred -->
            <p><i class="fas fa-exclamation-triangle"></i> An error ocurred</p>
          </div>
          <TournamentForm  @submit="submit" :strings="stringList" :bikes="bikeList" :circuits="circuitList" v-else-if="!isLoading && !cantRender" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TournamentForm from '@/components/TournamentForm.vue'

export default {
  components: {
    TournamentForm
  },
  data() {
    return {
      isLoading: false,
      cantRender: false,
      tournament: null,
      stringList: [],
      bikeList: [],
      circuitList: []
    }
  },
  mounted: function() {
    this.getData()
  },
  methods: {
    getData(){
      this.isLoading = true
      this.startLoading()
      const promises = [
        this.$http.get('/stringTable/list'),
        this.$http.get('/bikes/list'),
        this.$http.get('/circuits/list')
      ]
      Promise.all(promises)
      .then(responses => {
        this.stringList = responses[0].data
        this.bikeList = responses[1].data
        this.circuitList = responses[2].data
      })
      .catch(error => {
        this.cantRender = true
        this.showErrorNotification('Error fetching data from the server...')
        console.error(error)
      })
      .finally(() => {
        this.isLoading = false
        this.stopLoading()
      })
    },
    submit(payload) {
      this.startLoading()
      this.$http.post('/tournaments/new',payload).then((response) => {
        console.log(response)
        this.showSuccessNotification('Tournament created successfully!')
        this.$router.push({name: 'tournaments'})
      }).catch((err) => {
        this.showErrorNotification('An unexpected error occurred. Please try again later.')
        console.error(err)
      }).finally(() => {
        this.stopLoading()
      })
    }
  }
}
</script>