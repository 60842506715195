<template>
  <ValidationProvider :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules" v-slot="{ errors, valid }">
    <b-field v-bind="$attrs" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
      <b-input v-model="innerValue" v-bind="$attrs"></b-input>
    </b-field>
    <div class="message mb-2" v-if="help">
      <div class="message-body p-2">
        <p class="is-size-7">{{ help }}</p>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    help: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
