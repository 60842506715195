<template>
  <div>
    <div class="message is-warning" v-show="warningEdits.length > 0">
      <div class="message-body is-clearfix">
        <i class="fas fa-times-circle is-size-5 is-clickable is-pulled-right" @click="warningEdits = []"></i>
        <div class="content">

          <p>Please note the following warning:</p>
          <ul>
            <li v-for="(error,i) in warningEdits" :key="`warning-${i}-${error.slice(0,10)}`">{{error}}</li>
          </ul>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer">
      <!-- <BInputWithValidation rules="required|max:64" type="text" label="Name" v-model="name"/> -->
      <div class="field">
        <label for="" class="label">ID tournament</label>
        <div class="control">
          <input type="text" class="input" :value="`${edit ? id : ''}`" disabled placeholder="Autogenerated after creation">
        </div>
      </div>

      <b-field class="my-4" label="Name">
        <b-autocomplete
            ref="nameAutocomplete"
            v-model="nameInput"
            placeholder="Start typing to find a name or create a new one"
            :keep-first="false"
            :open-on-focus="false"
            :data="filteredNameStrings"
            field="value"
            @select="option => (nameSelected = option)"
            :clearable="true"
        >
        <!-- <template #empty>Create a new name "{{nameInput}}"</template> -->
        </b-autocomplete>
      </b-field>
      <div class="columns">
        <div class="column">
          <ValidationProvider rules="required" v-slot="{ errors, valid }">
            <b-field label="Start date & time (In local timezone)" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
              <b-datetimepicker
                v-model="startTimestamp"
                placeholder="Type or select a date..."
                icon="calendar"
                :timepicker="{'hour-format': '24'}"
                :datetime-formatter="datetimeToReadableFormat"
                icon-pack="fas"
                editable>
              </b-datetimepicker>
            </b-field>
          </ValidationProvider>
          <div class="message mb-2" v-if="startTimestamp">
            <div class="message-body p-2">
              <p class="is-size-7">In local time ({{myTimezone}}): <b>{{datetimeToReadableFormat(startTimestamp)}}</b></p>
              <p class="is-size-7">In Philippines time (GMT+8): <b>{{datetimeToPhilippinesToReadableFormat(startTimestamp)}}</b></p>
            </div>
          </div>
        </div>
        <div class="column">
          <ValidationProvider rules="required" v-slot="{ errors, valid }">
              <b-field label="End date & time (In local timezone)" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors" >
                <b-datetimepicker
                v-model="endTimestamp"
                placeholder="Type or select a date..."
                icon="calendar"
                :timepicker="{'hour-format': '24'}"
                :datetime-formatter="datetimeToReadableFormat"
                :hour-format="24"
                icon-pack="fas"
                editable>
              </b-datetimepicker>
            </b-field>
          </ValidationProvider>
          <div class="message mb-2" v-if="endTimestamp">
            <div class="message-body p-2">
              <p class="is-size-7">In local time ({{myTimezone}}): <b>{{datetimeToReadableFormat(endTimestamp)}}</b></p>
              <p class="is-size-7">In Philippines time (GMT+8): <b>{{datetimeToPhilippinesToReadableFormat(endTimestamp)}}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <BInputNumberWithValidation rules="integer|max_value:24|min_value:-24" type="number" label="Time offset (Hours)" v-model="timeOffsetHours" help="Can be between -24 and 24. If empty, by default is 0 (no offset)."/>
        </div>
        <div class="column">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Last entry (In minutes)" v-model="lastEntryInMinutes" help="If empty, by default is 15"/>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <BInputNumberWithValidation rules="required|integer|min_value:0" type="number" label="Restart Frequency" v-model="restartFrequency" help="In hours, when a new version of the tournament will start"/>
        </div>
        <div class="column">
          <BInputNumberWithValidation rules="required|integer|min_value:1" type="number" label="Duration" v-model="duration" help="In hours, how long this tournament will run once started. Must be a value equal or greater than 1"/>
        </div>
      </div>
      <BCheckboxesWithValidation rules="required|oneOf:0,1" label="isActivated">
        <b-field class="my-4" label="Is tournament activated?">
          <b-radio v-model="isActivated" native-value="0">Deactivated</b-radio>
          <b-radio v-model="isActivated" native-value="1">Activated</b-radio>
        </b-field>
      </BCheckboxesWithValidation>
      <b-field class="my-4" label="Country Whitelist">
        <b-autocomplete
            v-model="countryWhitelistInput"
            placeholder="Search a country..."
            :keep-first="false"
            :open-on-focus="false"
            :data="filteredCountriesWhitelist"
            field="name"
            @select="option => pushCountryWhitelist(option)"
        >
        <template #empty>No country called "{{countryWhitelistInput}}"</template>
        </b-autocomplete>
      </b-field>
      <div class="message">
        <div class="message-body" v-if="countryWhitelistArray.length == 0">
          <p class="is-size-7">If the country whitelist is empty, ALL countries are allowed.</p>
        </div>
        <div class="message-body" v-else>
          <p class="is-size-7 mb-3">The following countries are allowed:</p>
          <b-taglist>
            <b-tag v-for="country in countryWhitelistArray" :key="`whitelist-${country.code}`" type="is-primary" :closable="true" @close="removeCountryFromWhitelist(country.code)">
              {{ country.name }}
            </b-tag>
          </b-taglist>
        </div>
      </div>
      <b-field class="my-4" label="Country Blacklist">
        <b-autocomplete
            v-model="countryBlacklistInput"
            placeholder="Start typing to find a name"
            :keep-first="false"
            :open-on-focus="false"
            :data="filteredCountriesBlacklist"
            field="name"
            @select="option => pushCountryBlacklist(option)"
        >
          <template #empty>No country called "{{countryWhitelistInput}}"</template>
        </b-autocomplete>
      </b-field>
      <div class="message">
        <div class="message-body" v-if="countryBlacklistArray.length == 0">
          <p class="is-size-7">If the country blacklilst is empty, -NO- countries are blocked.</p>
        </div>
        <div class="message-body" v-else>
          <p class="is-size-7 mb-3">The following countries are not allowed:</p>
          <b-taglist>
            <b-tag v-for="country in countryBlacklistArray" :key="`blacklist-${country.code}`" type="is-danger" :closable="true" @close="removeCountryFromBlacklist(country.code)">
              {{ country.name }}
            </b-tag>
          </b-taglist>
        </div>
      </div>
      <BSelectWithValidation rules="required|oneOf:NONE,OPEN,TEAM,PRO TEAM,PROVIDED,SELECT" label="Bike restriction" v-model="bikeRestriction">
        <option value="NONE">None</option>
        <option value="SELECT">Select bikes</option>
        <option value="OPEN">Open</option>
        <option value="TEAM">Team</option>
        <option value="PRO TEAM">Pro Team</option>
        <option value="PROVIDED">Provided</option>
      </BSelectWithValidation>
      <div v-if="bikeRestriction == 'SELECT'">
        <b-field class="my-4" label="Bikes to restrict (Select)">
          <b-autocomplete
              v-model="bikeRestrictionInput"
              placeholder="Start typing to find a bike"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredBikeRestriction"
              field="name"
              @select="option => pushBikeRestrictionArray(option)"
          >
            <template #empty>No circuit found with name "{{bikeRestrictionInput}}"</template>
          </b-autocomplete>
        </b-field>
        <div class="message" :class="{'is-danger': bikeRestrictionArray.length == 0}">
          <div class="message-body" v-if="bikeRestrictionArray.length == 0">
            <p class="is-size-7"><b>REQUIRED</b>. Please, select at least one bike.</p>
          </div>
          <div class="message-body" v-else>
            <p class="is-size-7 mb-3">Restricted to the following bikes:</p>
            <b-taglist>
              <b-tag v-for="bike in bikeRestrictionArray" :key="`bike-restricted-${bike.id}`" type="is-primary" :closable="true" @close="removeBikeFromRestriction(circuit.id)">
                {{ bike.name }}
              </b-tag>
            </b-taglist>
          </div>
        </div>
      </div>
      <div v-if="bikeRestriction === 'PROVIDED'">
        <b-field class="my-4" label="Bike restriction (Provided)">
          <b-autocomplete
              ref="bikeProvidedAutocomplete"
              v-model="bikeProvidedInput"
              placeholder="Start typing to find a bike"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredBikeProvided"
              field="name"
              @select="option => (bikeProvidedSelected = option)"
              :clearable="true"
          >
            <template #empty>No bike found with name "{{bikeProvidedInput}}"</template>
          </b-autocomplete>
        </b-field>
      </div>
      <BCheckboxesWithValidation rules="required|oneOf:0,1" label="isBikeUpgradesAllowed">
        <b-field class="my-4" label="Is bike upgrades allowed?">
          <b-radio v-model="isBikeUpgradesAllowed" native-value="0">Don't allow bike upgrades</b-radio>
          <b-radio v-model="isBikeUpgradesAllowed" native-value="1">Allow bike upgrades</b-radio>
        </b-field>
      </BCheckboxesWithValidation>
      <BSelectWithValidation rules="required" label="Is compulsives allowed?" v-model="isCompulsivesAllowed">
        <option value="ALL">All</option>
        <option value="NONE">None</option>
      </BSelectWithValidation>
      <BCheckboxesWithValidation rules="required|oneOf:1,2,3,4,5" label="winningCondition">
        <b-field class="my-4" label="Winning condition">
          <b-radio v-model="winningCondition" native-value="1">High Score</b-radio>
          <b-radio v-model="winningCondition" native-value="2">Accumulated Score</b-radio>
          <b-radio v-model="winningCondition" native-value="3">Total Circuit Score</b-radio>
          <b-radio v-model="winningCondition" native-value="4">Best Daily Score</b-radio>
          <b-radio v-model="winningCondition" native-value="5">Lottery Tickets</b-radio>
        </b-field>
      </BCheckboxesWithValidation>
      <BCheckboxesWithValidation rules="required|oneOf:0,1,2" label="scoreDisplay">
        <b-field class="my-4" label="Display score?">
          <b-radio v-model="scoreDisplay" native-value="0">Don't display</b-radio>
          <b-radio v-model="scoreDisplay" native-value="1">Display Score</b-radio>
          <b-radio v-model="scoreDisplay" native-value="2">Display Percent Increase</b-radio>
        </b-field>
      </BCheckboxesWithValidation>
      <BInputNumberWithValidation rules="integer|max_value:24|min_value:0" type="number" label="Lap limit" v-model="lapLimit" help="Zero (0) is normal behaviour, otherwise enter the number of laps in race"/>
      <BSelectWithValidation rules="required" label="Circuit restrictions" v-model="selectedOptionCircuitRestriction">
        <option value="NONE">None</option>
        <option value="RANDOM">Random</option>
        <option value="LIMITED">Limited (Choose circuits...)</option>
      </BSelectWithValidation>
      <div v-if="selectedOptionCircuitRestriction == 'LIMITED'">
        <b-field class="my-4" label="Circuit restriction (Limited)">
          <b-autocomplete
              v-model="circuitRestrictionInput"
              placeholder="Start typing to find a circuit"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredCircuitRestriction"
              field="name"
              @select="option => pushCircuitRestrictionArray(option)"
          >
            <template #empty>No circuit found with name "{{circuitRestrictionInput}}"</template>
          </b-autocomplete>
        </b-field>
        <div class="message" :class="{'is-danger': circuitRestrictionArray.length == 0}">
          <div class="message-body" v-if="circuitRestrictionArray.length == 0">
            <p class="is-size-7"><b>REQUIRED</b>. Please, select at least one circuit to restrict.</p>
          </div>
          <div class="message-body" v-else>
            <p class="is-size-7 mb-3">Restricted to the following circuits:</p>
            <b-taglist>
              <b-tag v-for="circuit in circuitRestrictionArray" :key="`circuit-${circuit.id}`" type="is-primary" :closable="true" @close="removeCircuitFromRestriction(circuit.id)">
                {{ circuit.name }}
              </b-tag>
            </b-taglist>
          </div>
        </div>
      </div>
      <div class="columns my-2 is-multiline">
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|max_value:32|min_value:0" type="number" label="Player starting position" v-model="playerStartingPosition" help="Min: 0 / Max: 32"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|max_value:31|min_value:-1" type="number" label="Opponents Limit" v-model="opponentsLimit" help="Min: -1 / Max: 31"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Player MIN level" v-model="playerMinLevel"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Player MAX level" v-model="playerMaxLevel"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Bike MIN level" v-model="bikeMinLevel"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Bike MAX level" v-model="bikeMaxLevel"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Player MIN race" v-model="playerMinRace"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Player MAX race" v-model="playerMaxRace"/>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <BInputNumberWithValidation rules="required|integer|min_value:0|multipleOf100" type="number" label="Entry cost" help="IMPORTANT: The cost is the value times 100. Ex.: If the entry cost is 5 coins, the value to insert is 5 * 100 = 500. (The value must be a multiple of 100)" v-model="entryCost"/>
        </div>
        <div class="column is-6">
          <BSelectWithValidation rules="required" label="Entry cost currency" v-model="entryCurrency">
            <option value="1">Coins</option>
            <option value="2">Diamons</option>
            <option value="3">Dollars</option>
            <option value="4">Race Tickets</option>
          </BSelectWithValidation>
        </div>
      </div>
      <BCheckboxesWithValidation rules="required" label="isEntryFeeOnlyOnce">
        <b-field class="my-4" label="Is entry free only one">
          <b-radio v-model="isEntryFeeOnlyOnce" native-value="0">No</b-radio>
          <b-radio v-model="isEntryFeeOnlyOnce" native-value="1">Yes</b-radio>
        </b-field>
      </BCheckboxesWithValidation>
      <!-- ================================= -->
      <!-- PRIZE CLASS 01 -->
      <!-- ================================= -->
      <div class="card my-3">
        <div class="card-content">
          <h5 class="title is-5">Prize Class 1</h5>
          <BSelectWithValidation rules="required" label="Prize type" v-model="prizeClass1ID">
            <option value="0">No prize</option>
            <option value="1">Coins</option>
            <option value="2">Diamonds</option>
            <option value="3">Bike (Select bike...)</option>
            <option value="4">Physical Prize (Upload image...)</option>
          </BSelectWithValidation>
          <b-field class="my-4" label="Prize description" v-if="prizeClass1ID !== '0'">
            <b-autocomplete
              ref="prizeClass1DescAutocomplete"
              v-model="prizeClass1DescInput"
              placeholder="Start typing to find a name or create a new one"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredStringsPrizeClass1Desc"
              field="value"
              @select="option => (prizeClass1DescSelected = option)"
              :clearable="true"
            >
            <!-- <template #empty>Create a new name "{{nameInput}}"</template> -->
            </b-autocomplete>
          </b-field>
          <BInputNumberWithValidation v-if="prizeClass1ID === '1' || prizeClass1ID === '2'" rules="integer|min_value:0" type="number" label="Amount to win " v-model="prizeClass1Number"/>
          <b-field class="my-4" label="Bike to win" v-if="prizeClass1ID === '3'">
            <b-autocomplete
              ref="prizeClass1BikeAutocomplete"
              v-model="prizeClass1BikeInput"
              placeholder="Start typing to find a bike"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredPrizeClass1Bike"
              field="name"
              :clearable="true"
              @select="option => selectPrizeClass1Bike(option)"
            >
              <template #empty>No bike found with name "{{prizeClass1BikeInput}}"</template>
            </b-autocomplete>
          </b-field>
          <div class="box" v-if="prizeClass1ID === '4' && prizeClass1URL != null">
            <label class="label">Prize Class 1 - Picture</label>
            <p class="is-size-7 mb-3">(Original picture)</p>
            <img :src="`${apiUrl}/images/${prizeClass1URL}`" class="image mt-3" />
            <div class="buttons mt-3">
              <button class="button is-danger is-small" @click="prizeClass1URL = null">Remove</button>
            </div>            
          </div>
          <div class="box" v-if="prizeClass1ID === '4' && prizeClass1URL == null">
            <label class="label">Prize Class 1 - Picture</label>
            <p class="is-size-7 mb-3">Only PNG. Minimum size required: 234x129 px.</p>
            <div v-if="prizeClass1ImageResultData">
              <img :src="prizeClass1ImageResultData" class="image" />
            </div>
            <div class="notification-cropper is-size-7" v-if="prizeClass1Image.src">
              Please crop the image to the correct.<br>
              You can use the wheel mouse to zoom in and out.
            </div>
            <cropper
              ref="cropperPrize1Image"
              class="prize-cropper"
              backgroundClass="cropper-background"
              :canvas="theCanvas"
              :stencil-props="theStencil"
              :src="prizeClass1Image.src"
              :roundResult="false"
              @error="prizeClass1ImageLoaded = false"
              @ready="prizeClass1ImageLoaded = true"
              v-if="prizeClass1Image.src"
            />
            <div class="buttons mt-3" v-if="!prizeClass1ImageResultData">
              <button @click="$refs.prizeClass1ImageInput.click()"  class="button is-small" :class="{'is-primary': !prizeClass1Image.src, 'is-light': prizeClass1Image.src}">
                <span v-if="!prizeClass1Image.src"><i class="fas fa-search"></i>&nbsp;Upload file</span>
                <span v-else><i class="fas fa-undo"></i>&nbsp;Upload another image</span>
                <input type="file" ref="prizeClass1ImageInput" @change="uploadPrizeImage($event, 1)" accept="image/png" style="display:none"/>
              </button>
              <button class="button is-primary is-small" @click="cropPrizeClassImage(1)" :disabled="!prizeClass1ImageLoaded" v-if="prizeClass1Image.src"><i class="fas fa-crop"></i>&nbsp;Crop image</button>
            </div>
            <div class="buttons mt-3" v-if="prizeClass1ImageResultData && !prizeClass1Image.src">
              <button class="button is-danger is-small" @click="prizeClass1ImageResultData = null"><i class="fas fa-trash"></i>&nbsp;Remove image</button>
            </div>
          </div>
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Min Rank to win prize" v-model="prizeClass1Rank" v-if="prizeClass1ID !== '0'"/>
        </div>
      </div>
      <!-- ================================= -->
      <!-- PRIZE CLASS 02 -->
      <!-- ================================= -->
      <div class="card my-3">
        <div class="card-content">
          <h5 class="title is-5">Prize Class 2</h5>
          <BSelectWithValidation rules="required" label="Prize type" v-model="prizeClass2ID">
            <option value="0">No prize</option>
            <option value="1">Coins</option>
            <option value="2">Diamonds</option>
            <option value="3">Bike (Select bike...)</option>
            <option value="4">Physical Prize (Upload image...)</option>
          </BSelectWithValidation>
          <b-field class="my-4" label="Prize description" v-if="prizeClass2ID !== '0'">
            <b-autocomplete
              ref="prizeClass2DescAutocomplete"
              v-model="prizeClass2DescInput"
              placeholder="Start typing to find a name or create a new one"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredStringsPrizeClass2Desc"
              field="value"
              @select="option => (prizeClass2DescSelected = option)"
              :clearable="true"
            >
            <!-- <template #empty>Create a new name "{{nameInput}}"</template> -->
            </b-autocomplete>
          </b-field>
          <BInputNumberWithValidation v-if="prizeClass2ID === '1' || prizeClass2ID === '2'" rules="integer|min_value:0" type="number" label="Amount to win " v-model="prizeClass2Number"/>
          <b-field class="my-4" label="Bike to win" v-if="prizeClass2ID === '3'">
            <b-autocomplete
              ref="prizeClass2BikeAutocomplete"
              v-model="prizeClass2BikeInput"
              placeholder="Start typing to find a name"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredPrizeClass2Bike"
              field="name"
              @select="option => selectPrizeClass2Bike(option)"
            >
              <template #empty>No bike found with name "{{prizeClass2BikeInput}}"</template>
            </b-autocomplete>
          </b-field>
          <div class="box" v-if="prizeClass2ID === '4' && prizeClass2URL != null">
            <label class="label">Prize Class 2 - Picture</label>
            <p class="is-size-7 mb-3">(Original picture)</p>
            <img :src="`${apiUrl}/images/${prizeClass2URL}`" class="image mt-3" />
            <div class="buttons mt-3">
              <button class="button is-danger is-small" @click="prizeClass2URL = null">Remove</button>
            </div>            
          </div>
          <div class="box" v-if="prizeClass2ID === '4' && prizeClass2URL == null">
            <label class="label">Prize Class 2 - Picture</label>
            <p class="is-size-7 mb-3">Only PNG. Minimum size required: 234x129 px.</p>
            <div v-if="prizeClass2ImageResultData">
              <img :src="prizeClass2ImageResultData" class="image" />
            </div>
            <div class="notification-cropper is-size-7" v-if="prizeClass2Image.src">
              Please crop the image to the correct.<br>
              You can use the wheel mouse to zoom in and out.
            </div>
            <cropper
              ref="cropperPrize2Image"
              class="prize-cropper"
              backgroundClass="cropper-background"
              :canvas="theCanvas"
              :stencil-props="theStencil"
              :src="prizeClass2Image.src"
              :roundResult="false"
              @error="prizeClass2ImageLoaded = false"
              @ready="prizeClass2ImageLoaded = true"
              v-if="prizeClass2Image.src"
            />
            <div class="buttons mt-3" v-if="!prizeClass2ImageResultData">
              <button @click="$refs.prizeClass2ImageInput.click()"  class="button is-small" :class="{'is-primary': !prizeClass2Image.src, 'is-light': prizeClass2Image.src}">
                <span v-if="!prizeClass2Image.src"><i class="fas fa-search"></i>&nbsp;Upload file</span>
                <span v-else><i class="fas fa-undo"></i>&nbsp;Upload another image</span>
                <input type="file" ref="prizeClass2ImageInput" @change="uploadPrizeImage($event,2)" accept="image/png" style="display:none"/>
              </button>
              <button class="button is-primary is-small" @click="cropPrizeClassImage(2)" :disabled="!prizeClass2ImageLoaded" v-if="prizeClass2Image.src"><i class="fas fa-crop"></i>&nbsp;Crop image</button>
            </div>
            <div class="buttons mt-3" v-if="prizeClass2ImageResultData && !prizeClass2Image.src">
              <button class="button is-danger is-small" @click="prizeClass2ImageResultData = null"><i class="fas fa-trash"></i>&nbsp;Remove image</button>
            </div>
          </div>
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Min Rank to win prize" v-model="prizeClass2Rank" v-if="prizeClass2ID !== '0'"/>
        </div>
      </div>
      <!-- ================================= -->
      <!-- PRIZE CLASS 03 -->
      <!-- ================================= -->
      <div class="card my-3">
        <div class="card-content">
          <h5 class="title is-5">Prize Class 3</h5>
          <BSelectWithValidation rules="required" label="Prize type" v-model="prizeClass3ID">
            <option value="0">No prize</option>
            <option value="1">Coins (Enter amount...)</option>
            <option value="2">Diamonds (Enter amount...)</option>
            <option value="3">Bike (Select bike...)</option>
            <option value="4">Physical Prize (Upload image...)</option>
          </BSelectWithValidation>
          <b-field class="my-4" label="Prize description" v-if="prizeClass3ID !== '0'">
            <b-autocomplete
              ref="prizeClass3DescAutocomplete"
              v-model="prizeClass3DescInput"
              placeholder="Start typing to find a name or create a new one"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredStringsPrizeClass3Desc"
              field="value"
              @select="option => (prizeClass3DescSelected = option)"
              :clearable="true"
            >
            <!-- <template #empty>Create a new name "{{nameInput}}"</template> -->
            </b-autocomplete>
          </b-field>
          <BInputNumberWithValidation v-if="prizeClass3ID === '1' || prizeClass3ID === '2'" rules="integer|min_value:0" type="number" label="Amount to win " v-model="prizeClass3Number"/>
          <b-field class="my-4" label="Bike to win" v-if="prizeClass3ID === '3'">
            <b-autocomplete
              ref="prizeClass3BikeAutocomplete"
              v-model="prizeClass3BikeInput"
              placeholder="Start typing to find a name"
              :keep-first="false"
              :open-on-focus="false"
              :data="filteredPrizeClass3Bike"
              field="name"
              @select="option => selectPrizeClass3Bike(option)"
            >
              <template #empty>No bike found with name "{{prizeClass3BikeInput}}"</template>
            </b-autocomplete>
          </b-field>
          <div class="box" v-if="prizeClass3ID === '4' && prizeClass3URL != null">
            <label class="label">Prize Class 3 - Picture</label>
            <p class="is-size-7 mb-3">(Original picture)</p>
            <img :src="`${apiUrl}/images/${prizeClass3URL}`" class="image mt-3" />
            <div class="buttons mt-3">
              <button class="button is-danger is-small" @click="prizeClass3URL = null">Remove</button>
            </div>            
          </div>
          <div class="box" v-if="prizeClass3ID === '4' && prizeClass3URL == null">
            <label class="label">Prize Class 3 - Picture</label>
            <p class="is-size-7 mb-3">Only PNG. Minimum size required: 234x129 px.</p>
            <div v-if="prizeClass3ImageResultData">
              <img :src="prizeClass3ImageResultData" class="image" />
            </div>
            <div class="notification-cropper is-size-7" v-if="prizeClass3Image.src">
              Please crop the image to the correct.<br>
              You can use the wheel mouse to zoom in and out.
            </div>
            <cropper
              ref="cropperPrize3Image"
              class="prize-cropper"
              backgroundClass="cropper-background"
              :canvas="theCanvas"
              :stencil-props="theStencil"
              :src="prizeClass3Image.src"
              :roundResult="false"
              @error="prizeClass3ImageLoaded = false"
              @ready="prizeClass3ImageLoaded = true"
              v-if="prizeClass3Image.src"
            />
            <div class="buttons mt-3" v-if="!prizeClass3ImageResultData">
              <button @click="$refs.prizeClass3ImageInput.click()"  class="button is-small" :class="{'is-primary': !prizeClass3Image.src, 'is-light': prizeClass3Image.src}">
                <span v-if="!prizeClass3Image.src"><i class="fas fa-search"></i>&nbsp;Upload file</span>
                <span v-else><i class="fas fa-undo"></i>&nbsp;Upload another image</span>
                <input type="file" ref="prizeClass3ImageInput" @change="uploadPrizeImage($event,3)" accept="image/png" style="display:none"/>
              </button>
              <button class="button is-primary is-small" @click="cropPrizeClassImage(3)" :disabled="!prizeClass3ImageLoaded" v-if="prizeClass3Image.src"><i class="fas fa-crop"></i>&nbsp;Crop image</button>
            </div>
            <div class="buttons mt-3" v-if="prizeClass3ImageResultData && !prizeClass3Image.src">
              <button class="button is-danger is-small" @click="prizeClass3ImageResultData = null"><i class="fas fa-trash"></i>&nbsp;Remove image</button>
            </div>
          </div>
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Min Rank to win prize" v-model="prizeClass3Rank" v-if="prizeClass3ID !== '0'"/>
        </div>
      </div>
      <b-field class="my-4" label="Sponsor Name">
        <b-autocomplete
            ref="sponsorNameAutocomplete"
            v-model="sponsorNameInput"
            placeholder="Start typing to find a string or create a new one"
            :keep-first="false"
            :open-on-focus="false"
            :data="filteredSponsorNameStrings"
            field="value"
            @select="option => (sponsorNameSelected = option)"
        >
          <template #empty>No string in MST found with "{{sponsorNameInput}}"</template>
        </b-autocomplete>
      </b-field>
      <b-field class="my-4" label="Sponsor Slogan">
        <b-autocomplete
            ref="sponsorSloganAutocomplete"
            v-model="sponsorSloganInput"
            placeholder="Start typing to find a string or create a new one"
            :keep-first="false"
            :open-on-focus="false"
            :data="filteredSponsorSloganStrings"
            field="value"
            @select="option => (sponsorSloganSelected = option)"
        >
          <template #empty>No string in MST found with "{{sponsorSloganInput}}"</template>
        </b-autocomplete>
      </b-field>
      <BCheckboxesWithValidation rules="required|" label="filterable">
        <b-field class="my-4" label="Filterable?">
          <b-radio v-model="filterable" native-value="0">No</b-radio>
          <b-radio v-model="filterable" native-value="1">Yes</b-radio>
        </b-field>
      </BCheckboxesWithValidation>
      <BSelectWithValidation rules="required" label="Rules modifications" v-model="rulesModification">
        <option value="NONE">None</option>
        <option value="no_coins">No coins</option>
        <option value="brakes_2xcombo">Brakes 2x combo</option>
      </BSelectWithValidation>
      <div class="my-4" v-if="prizeClass1ID == '4'">
        <BInputWithValidation rules="" label="Helpshift Link Display" v-model="helpshiftLinkDisplay" />
      </div>
      <div class="columns is-multiline my-4">
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Sort Order" v-model="sortOrder"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0" type="number" label="Min IAP" v-model="minIAP"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation rules="integer|min_value:0|max_value:10" type="number" label="Worst Division" v-model="worstDivision"/>
        </div>
        <div class="column is-6">
          <BInputNumberWithValidation :rules="`integer|min_value:0|max_value:${worstDivision ? worstDivision : '10'}`" type="number" label="Best Division" v-model="bestDivision"/>
        </div>
      </div>
      <div class="message is-danger" v-show="customValidationErrors.length > 0">
        <div class="message-body is-clearfix">
          <i class="fas fa-times-circle is-size-5 is-clickable is-pulled-right" @click="customValidationErrors = []"></i>
          <div class="content">

            <p>Please note the following errors:</p>
            <ul>
              <li v-for="(error,i) in customValidationErrors" :key="`error-${i}-${error.slice(0,10)}`">{{error}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="button is-primary is-fullwidth" @click="handleSubmit"><i class="fas fa-save"></i>&nbsp;Save</button>
      </div>
    </ValidationObserver>

  </div>

</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BSelectWithValidation from "@/components/inputs/BSelectWithValidation";
import BInputWithValidation from "@/components/inputs/BInputWithValidation";
import BInputNumberWithValidation from "@/components/inputs/BInputNumberWithValidation";
import BCheckboxesWithValidation from "@/components/inputs/BCheckboxesWithValidation";
import { Cropper } from 'vue-advanced-cropper'


import { countries } from "@/data/countries.js";
import moment from "moment-timezone";


export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    strings: {
      type: Array,
      default: () => []
    },
    bikes: {
      type: Array,
      default: () => []
    },
    circuits: {
      type: Array,
      default: () => []
    },
    tournament: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BSelectWithValidation,
    BInputWithValidation,
    BInputNumberWithValidation,
    BCheckboxesWithValidation,
    Cropper
  },
  data(){
    return {
      initializated: false,
      // aux stuff
      nameInput: '',
      nameSelected: null,
      countryWhitelistInput: '',
      countryBlacklistInput: '',
      countryWhitelistArray: [],
      countryBlacklistArray: [],
      bikeRestrictionInput: '',
      bikeRestrictionArray: [],
      bikeProvidedInput: '',
      bikeProvidedSelected: null,
      selectedOptionCircuitRestriction: 'RANDOM',
      circuitRestrictionInput: '',
      circuitRestrictionArray: [],
      prizeClass1DescInput: '',
      prizeClass1DescSelected: null,
      prizeClass1BikeInput: '',
      prizeClass1BikeSelected: null,
      prizeClass2DescInput: '',
      prizeClass2DescSelected: null,
      prizeClass3DescInput: '',
      prizeClass3DescSelected: null,
      prizeClass1Image: {
				src: null,
				type: null
			},
      prizeClass1ImageLoaded: false,
      prizeClass1ImageResultData: null,
      prizeClass2Image: {
				src: null,
				type: null
			},
      prizeClass2ImageLoaded: false,
      prizeClass2ImageResultData: null,
      prizeClass3Image: {
				src: null,
				type: null
			},
      prizeClass3ImageLoaded: false,
      prizeClass3ImageResultData: null,
      theCanvas: {
        minHeight: 129,
        maxHeight: 129,
        maxWidth: 234,
        minWidth: 234
      },
      // theStencilSize: {
      //   width: 234,
      //   height: 129
      // },
      theStencil: { 
        aspectRatio: 78/43
      },
      sponsorNameInput: '',
      sponsorNameSelected: null,
      sponsorSloganInput: '',
      sponsorSloganSelected: null,
      isLoading: false,
      customValidationErrors: [],
      warningEdits: [],
      // Tournament Data
      id: null,
      // name: null,
      startTimestamp: null,
      endTimestamp: null,
      timeOffsetHours: null,
      restartFrequency: null,
      duration: null,
      lastEntryInMinutes: 15,
      isActivated: '0',
      // countryWhiteList: null,
      // countryBlackList: null,
      bikeRestriction: 'NONE',
      // bikeProvided: null,
      isBikeUpgradesAllowed: '1',
      isCompulsivesAllowed: 'ALL',
      winningCondition: '1',
      scoreDisplay: '0',
      lapLimit: 0,
      // circuitRestriction: null,
      playerStartingPosition: null,
      opponentsLimit: -1,
      playerMaxLevel: null,
      playerMinLevel: null,
      bikeMaxLevel: null,
      bikeMinLevel: null,
      playerMaxRace: null,
      playerMinRace: null,
      entryCost: 0,
      entryCurrency: '1',
      isEntryFeeOnlyOnce: '0',
      prizeClass1ID: '0',
      prizeClass1Desc: null,
      prizeClass1URL: null,
      prizeClass1Number: null,
      prizeClass1Rank: null,
      prizeClass2ID: '0',
      prizeClass2Desc: null,
      prizeClass2URL: null,
      prizeClass2Number: null,
      prizeClass2Rank: null,
      prizeClass3ID: '0',
      prizeClass3Desc: null,
      prizeClass3URL: null,
      prizeClass3Number: null,
      prizeClass3Rank: null,
      // sponsorName: null,
      // sponsorSlogan: null,
      filterable: '1',
      rulesModification: 'NONE',
      helpshiftLinkDisplay: '',
      sortOrder: '0',
      minIAP: '0',
      worstDivision: '10',
      bestDivision: '1'
    }
  },
  beforeMount() {
    if(this.edit){
      this.loadTournamentData()
    } else {
      this.initializated = true
    }
  },
  mounted(){
    if(this.edit){
      this.updateRefs()
    }
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.prizeClass1Image.src) {
			URL.revokeObjectURL(this.prizeClass1Image.src)
		}
    if (this.prizeClass2Image.src) {
			URL.revokeObjectURL(this.prizeClass2Image.src)
		}
    if (this.prizeClass3Image.src) {
			URL.revokeObjectURL(this.prizeClass3Image.src)
		}
  },
  methods: {
    datetimeToReadableFormat(datetime) {
      return moment(datetime).format('YYYY-MM-DD HH:mm:ss')
    },
    datetimeToPhilippinesToReadableFormat(datetime) {
      return moment(datetime).tz('Asia/Manila').format('YYYY-MM-DD HH:mm:ss')
    },
    handleSubmit() {
      this.customValidationErrors = [];
      this.$refs.observer.validate().then(success => {
        if (success) {
          // continue with validation in the next then()
          return false
        } else {
          this.showErrorNotification('Some fields are invalid or missing, please check the form');
          this.customValidationErrors.push('Some fields are invalid or missing, please check the form');
          return true
        }
      })
      .then((alreadyFoundErrors) => {
        let foundErrors = alreadyFoundErrors
        if(this.nameSelected == null && this.nameInput == '') {
          this.customValidationErrors.push('Tournament name not selected or defined: You must select a string from the MST or define a new one');
          foundErrors = true
        }
        if(this.startTimestamp == null) {
          this.customValidationErrors.push('Start date not selected: You must select a date');
          foundErrors = true
        }
        if(this.endTimestamp == null) {
          this.customValidationErrors.push('End date not selected: You must select a date');
          foundErrors = true
        }
        if (this.bikeRestriction === 'PROVIDED'){
          if(!this.bikeProvidedSelected) {
            this.customValidationErrors.push('No bike for bike restriction "PROVIDED": You must provide a bike to the restriction');
            foundErrors = true
          }
        }
        if(this.bikeRestriction === 'SELECT'){
          if(this.bikeRestrictionArray.length === 0) {
            this.customValidationErrors.push('No bike(s) for bike restriction "SELECT": You must select at least one bike');
            foundErrors = true
          }
        }
        if(this.selectedOptionCircuitRestriction === 'LIMITED'){
          if(this.circuitRestrictionArray.length === 0) {
            this.customValidationErrors.push('Circuit restriction not selected: You must select at least one circuit');
            foundErrors = true
          }
        }
        // For prize class 1
        if(this.prizeClass1ID === '1' || this.prizeClass1ID === '2'){
          if(!this.prizeClass1Number) {
            this.customValidationErrors.push('Prize 1: No prize amount given - You must enter a value');
            foundErrors = true
          }
        } else if(this.prizeClass1ID === '3') {
          if(!this.prizeClass1BikeSelected){
            this.customValidationErrors.push('Prize 1: No bike selected: You must select a bike as a prize');
            foundErrors = true
          }
        }
        // } else if(this.prizeClass1ID === '4'){
        //   if(!this.prizeClass1ImageResultData && !this.prizeClass1URL) {
        //     this.customValidationErrors.push('Prize 1: No image given: You must upload an image for the prize');
        //     foundErrors = true
        //   }
        // }

        // For prize class 2
        if(this.prizeClass2ID === '1' || this.prizeClass2ID === '2'){
          if(!this.prizeClass2Number) {
            this.customValidationErrors.push('Prize 2: No prize amount given - You must enter a value');
            foundErrors = true
          }
        } else if(this.prizeClass2ID === '3') {
          if(!this.prizeClass2BikeSelected){
            this.customValidationErrors.push('Prize 2: No bike selected: You must select a bike as a prize');
            foundErrors = true
          }
        }
        // } else if(this.prizeClass2ID === '4'){
        //   if(!this.prizeClass2ImageResultData && !this.prizeClass2URL) {
        //     this.customValidationErrors.push('Prize 2: No image given: You must upload an image for the prize');
        //     foundErrors = true
        //   }
        // }

        // For prize class 3
        if(this.prizeClass3ID === '1' || this.prizeClass3ID === '2'){
          if(!this.prizeClass3Number) {
            this.customValidationErrors.push('Prize 3: No prize amount given - You must enter a value');
            foundErrors = true
          }
        } else if(this.prizeClass3ID === '3') {
          if(!this.prizeClass3BikeSelected){
            this.customValidationErrors.push('Prize 3: No bike selected: You must select a bike as a prize');
            foundErrors = true
          }
        }
        // } else if(this.prizeClass3ID === '4'){
        //   if(!this.prizeClass3ImageResultData && !this.prizeClass3URL) {
        //     this.customValidationErrors.push('Prize 3: No image given: You must upload an image for the prize');
        //     foundErrors = true
        //   }
        // }
        // if(this.prizeClass1ID === '4'){
        //   if(!this.helpshiftLinkDisplay){
        //     this.customValidationErrors.push('No helpshift link display given: Because prize 1 type was physical, this is required to be completed');
        //     foundErrors = true
        //   }
        // }
        // if(this.sponsorNameSelected == null && this.sponsorNameInput == '') {
        //   this.customValidationErrors.push('Sponsor name not selected or defined: You must select a string from the MST or define a new one');
        //   foundErrors = true
        // }
        // if(this.sponsorSloganSelected == null && this.sponsorSloganInput == '') {
        //   this.customValidationErrors.push('Sponsor slogan not selected or defined: You must select a string from the MST or define a new one');
        //   foundErrors = true
        // }
        
        return foundErrors
      })
      .then((foundErrors) => {
        if(foundErrors){
          this.showErrorNotification('Some fields are invalid or missing, please check the form');
        } else {
          this.makePayload()
        }
      })
      .catch(error => {
        this.showErrorNotification(error.message ? error.message : 'Validation failed, please check the form');
        console.error(error);
      })
    },
    makePayload() {
      const tournamentPayload = {}
      if(this.nameInput && this.nameSelected == null) {
        tournamentPayload.name = `*${this.nameInput}`
      } else {
        tournamentPayload.name = this.nameSelected.name
      }
      // // convert start and end date to unix timestamp (the server will save it in the corresponding timezone, which is Philippines +8 UTC)
      tournamentPayload.startTimestamp = moment(this.startTimestamp).unix()
      tournamentPayload.endTimestamp = moment(this.endTimestamp).unix()
      
      tournamentPayload.restartFrequency = parseInt(this.restartFrequency)
      tournamentPayload.duration = parseInt(this.duration)
      
      if(this.timeOffsetHours) {
        tournamentPayload.timeOffsetHours = parseInt(this.timeOffsetHours)
      }
      if(this.lastEntryInMinutes) {
        tournamentPayload.lastEntryInMinutes = parseInt(this.lastEntryInMinutes)
      }
      tournamentPayload.isActivated = parseInt(this.isActivated)
      if(this.countryWhitelistArray.length > 0) {
        // conver to pipe delimited by string of 2 char country codes
        tournamentPayload.countryWhiteList = this.countryWhitelistArray.map(country => country.code).join('|')
      } else {
        tournamentPayload.countryWhiteList = 'ALL'
      }
      if(this.countryBlacklistArray.length > 0) {
        // conver to pipe delimited by string of 2 char country codes
        tournamentPayload.countryBlackList = this.countryBlacklistArray.map(country => country.code).join('|')
      } else {
        // do nothing, could be a empty string but we are not sending it...
      }
      // tournamentPayload.bikeRestriction = this.bikeRestriction
      if(this.bikeRestriction === 'SELECT'){
        tournamentPayload.bikeRestriction = this.bikeRestrictionArray.map(bike => bike.id).join('|')
      } else if(this.bikeRestriction === 'PROVIDED' && this.bikeProvidedSelected){
        tournamentPayload.bikeRestriction = 'PROVIDED'
        tournamentPayload.bikeProvided = this.bikeProvidedSelected.id
      } else if(this.bikeRestriction === 'PRO TEAM'){
        tournamentPayload.bikeRestriction = 'Pro Team'
      } else {
        tournamentPayload.bikeRestriction = this.bikeRestriction
      }
      if(this.bikeRestriction !== 'PROVIDED'){
        tournamentPayload.bikeProvided = 0 // if no bike provided, set to 0
      }
      tournamentPayload.isBikeUpgradesAllowed = parseInt(this.isBikeUpgradesAllowed)
      tournamentPayload.isCompulsivesAllowed = this.isCompulsivesAllowed
      tournamentPayload.winningCondition = parseInt(this.winningCondition)
      tournamentPayload.scoreDisplay = parseInt(this.scoreDisplay)
      if(this.lapLimit) {
        tournamentPayload.lapLimit = parseInt(this.lapLimit)
      }
      if(this.selectedOptionCircuitRestriction === 'LIMITED' && this.circuitRestrictionArray.length > 0){
        tournamentPayload.circuitRestriction = this.circuitRestrictionArray.map(circuit => circuit.id).join('|')
      } else if(this.selectedOptionCircuitRestriction === 'NONE') {
        tournamentPayload.circuitRestriction = ''
      } else if(this.selectedOptionCircuitRestriction === 'RANDOM') {
        tournamentPayload.circuitRestriction = 'RANDOM'
      }
      if(this.isOptional(this.playerStartingPosition)) {
        tournamentPayload.playerStartingPosition = parseInt(this.playerStartingPosition)
      }
      if(this.isOptional(this.opponentsLimit)){
        tournamentPayload.opponentsLimit = parseInt(this.opponentsLimit)
      }
      if(this.isOptional(this.playerMaxLevel)){
        tournamentPayload.playerMaxLevel = parseInt(this.playerMaxLevel)
      }
      if(this.isOptional(this.playerMinLevel)){
        tournamentPayload.playerMinLevel = parseInt(this.playerMinLevel)
      }
      if(this.isOptional(this.bikeMaxLevel)){
        tournamentPayload.bikeMaxLevel = parseInt(this.bikeMaxLevel)
      }
      if(this.isOptional(this.bikeMinLevel)){
        tournamentPayload.bikeMinLevel = parseInt(this.bikeMinLevel)
      }
      if(this.isOptional(this.playerMaxRace)){
        tournamentPayload.playerMaxRace = parseInt(this.playerMaxRace)
      }
      if(this.isOptional(this.playerMinRace)){
        tournamentPayload.playerMinRace = parseInt(this.playerMinRace)
      }
      tournamentPayload.entryCost = parseInt(this.entryCost)
      tournamentPayload.entryCurrency = parseInt(this.entryCurrency)
      tournamentPayload.isEntryFeeOnlyOnce = parseInt(this.isEntryFeeOnlyOnce)

      // For prize class 1
      tournamentPayload.prizeClass1ID = parseInt(this.prizeClass1ID)
      if(this.prizeClass1ID !== '0'){
        if(this.prizeClass1DescInput != '' && this.prizeClass1DescSelected == null) {
          tournamentPayload.prizeClass1Desc = `*${this.prizeClass1DescInput}`
        } else if(this.prizeClass1DescSelected != null) {
          tournamentPayload.prizeClass1Desc = this.prizeClass1DescSelected.name
        } else {
          // dont send prizeClass1Desc
        }
        if(this.prizeClass1ID === '1' || this.prizeClass1ID === '2'){
          tournamentPayload.prizeClass1Number = parseInt(this.prizeClass1Number)
        } else if(this.prizeClass1ID === '3') {
          tournamentPayload.prizeClass1Number = parseInt(this.prizeClass1BikeSelected.id)
        } else if(this.prizeClass1ID === '4'){
          if(this.prizeClass1ImageResultData){
            tournamentPayload.prizeClass1URL = this.prizeClass1ImageResultData
          } else if(this.prizeClass1URL) {
            tournamentPayload.prizeClass1URL = this.prizeClass1URL
          }
        }
        if(this.isOptional(this.prizeClass1Rank)){
          tournamentPayload.prizeClass1Rank = parseInt(this.prizeClass1Rank)
        }
      }

      // For prize class 2
      tournamentPayload.prizeClass2ID = parseInt(this.prizeClass2ID)
      if(this.prizeClass2ID !== '0'){
        if(this.prizeClass2DescInput != '' && this.prizeClass2DescSelected == null) {
          tournamentPayload.prizeClass2Desc = `*${this.prizeClass2DescInput}`
        } else if(this.prizeClass2DescSelected != null) {
          tournamentPayload.prizeClass2Desc = this.prizeClass2DescSelected.name
        } else {
          // dont send prizeClass2Desc
        }
        if(this.prizeClass2ID === '1' || this.prizeClass2ID === '2'){
          tournamentPayload.prizeClass2Number = parseInt(this.prizeClass2Number)
        } else if(this.prizeClass2ID === '3') {
          tournamentPayload.prizeClass2Number = parseInt(this.prizeClass2BikeSelected.id)
        } else if(this.prizeClass2ID === '4'){
          if(this.prizeClass2ImageResultData){
            tournamentPayload.prizeClass2URL = this.prizeClass2ImageResultData
          } else if(this.prizeClass2URL) {
            tournamentPayload.prizeClass2URL = this.prizeClass2URL
          }
        }
        if(this.isOptional(this.prizeClass2Rank)){
          tournamentPayload.prizeClass2Rank = parseInt(this.prizeClass2Rank)
        }
      }

      // For prize class 3
      tournamentPayload.prizeClass3ID = parseInt(this.prizeClass3ID)
      if(this.prizeClass3ID !== '0'){
        if(this.prizeClass3DescInput != '' && this.prizeClass3DescSelected == null) {
          tournamentPayload.prizeClass3Desc = `*${this.prizeClass3DescInput}`
        } else if(this.prizeClass3DescSelected != null) {
          tournamentPayload.prizeClass3Desc = this.prizeClass3DescSelected.name
        } else {
          // dont send prizeClass3Desc
        }
        if(this.prizeClass3ID === '1' || this.prizeClass3ID === '2'){
          tournamentPayload.prizeClass3Number = parseInt(this.prizeClass3Number)
        } else if(this.prizeClass3ID === '3') {
          tournamentPayload.prizeClass3Number = parseInt(this.prizeClass3BikeSelected.id)
        } else if(this.prizeClass3ID === '4'){
          if(this.prizeClass3ImageResultData){
            tournamentPayload.prizeClass3URL = this.prizeClass3ImageResultData
          } else if(this.prizeClass3URL) {
            tournamentPayload.prizeClass3URL = this.prizeClass3URL
          }
        }
        if(this.isOptional(this.prizeClass3Rank)){
          tournamentPayload.prizeClass3Rank = parseInt(this.prizeClass3Rank)
        }
      }

      if (this.sponsorNameSelected != null) {
        tournamentPayload.sponsorName = this.sponsorNameSelected.name
      } else if(this.sponsorNameInput != '' && this.sponsorNameSelected == null) {
        tournamentPayload.sponsorName = `*${this.sponsorNameInput}`
      } else {
        tournamentPayload.sponsorName = ''
      }

      if(this.sponsorNameSelected != null) {
        tournamentPayload.sponsorSlogan = this.sponsorSloganSelected.name
      } else if(this.sponsorSloganInput != '' || this.sponsorSloganSelected != null) {
        tournamentPayload.sponsorSlogan = `*${this.sponsorSloganInput}`
      } else {
        tournamentPayload.sponsorSlogan = ''
      }

      tournamentPayload.filterable = parseInt(this.filterable)
      tournamentPayload.rulesModification = this.rulesModification
      if(this.prizeClass1ID === '4' && this.isOptional(this.helpshiftLinkDisplay)){
        tournamentPayload.helpshiftLinkDisplay = this.helpshiftLinkDisplay
      }
      if(this.isOptional(this.sortOrder)){
        tournamentPayload.sortOrder = parseInt(this.sortOrder)
      }
      if(this.isOptional(this.minIAP)) {
        tournamentPayload.minIAP = parseInt(this.minIAP)
      }
      if(this.isOptional(this.worstDivision)) {
        tournamentPayload.worstDivision = parseInt(this.worstDivision)
      }
      if(this.isOptional(this.bestDivision)) {
        tournamentPayload.bestDivision = parseInt(this.bestDivision)
      }

      // console.log(tournamentPayload)
      this.$emit('submit', tournamentPayload)
    },
    loadTournamentData() {
      this.id = this.tournament.id      
      // if(this.tournament.name)
      if(this.tournament.name !== undefined) {
        if(this.tournament.name[0] === '*'){
          this.nameInput = this.tournament.name.slice(1)
        } else {
          let nameOption = this.strings.find(string => string.name === this.tournament.name)
          if(nameOption === undefined){
            this.warningEdits.push(`Tournament name "${this.tournament.name}" is not a custom string and is not in the MST list. Please assign a correct string or write a new custom name.`)
          } else {
            this.nameSelected = nameOption
            this.nameInput = nameOption.value
          }
        }
      }
      if(this.tournament.isActivated != undefined) {
        this.isActivated = this.tournament.isActivated.toString()
      }
      if(this.tournament.startTimestamp){
        // convert this timestamp to Date
        let philippinesStartTimestamp = this.tournament.startTimestamp.split(' ')[0] + 'T' + this.tournament.startTimestamp.split(' ')[1] + '+08:00'
        let date = new Date(philippinesStartTimestamp)
        // let date = new Date(this.tournament.startTimestamp)
        this.startTimestamp = date
      }
      if(this.tournament.endTimestamp){
        // convert this timestamp to Date
        let philippinesEndTimestamp = this.tournament.endTimestamp.split(' ')[0] + 'T' + this.tournament.endTimestamp.split(' ')[1] + '+08:00'
        let date = new Date(philippinesEndTimestamp)
        // let date = new Date(this.tournament.endTimestamp)
        this.endTimestamp = date
      }
      if(this.tournament.timeOffsetHours != undefined){
        this.timeOffsetHours = this.tournament.timeOffsetHours.toString()
      }
      if(this.tournament.lastEntryInMinutes != undefined){
        this.lastEntryInMinutes = this.tournament.lastEntryInMinutes.toString()
      }
      if(this.tournament.restartFrequency != undefined){
        this.restartFrequency = this.tournament.restartFrequency.toString()
      }
      if(this.tournament.duration != undefined){
        this.duration = this.tournament.duration.toString()
      }
      if(this.tournament.countryWhiteList != 'ALL' && this.tournament.countryWhiteList != undefined && this.tournament.countryWhiteList != ''){
        let auxCountryWhiteListArray = this.tournament.countryWhiteList.split('|')
        this.countryWhitelistArray = auxCountryWhiteListArray.map(countryCode => {
          let countryFound = countries.find(country => country.code === countryCode)
          if(countryFound === undefined){
            this.warningEdits.push(`Country code "${countryCode}" for the "countryWhiteList" not found. Might be a wrong country code or not in the countries list. Please check with admin.`)
            // THIS COULD BE A PROBLEM
            return {code: countryCode, name: '?????????'}
          } else {
            return countryFound
          }
        })
      }
      if(this.tournament.countryBlackList != 'ALL' && this.tournament.countryBlackList != undefined && this.tournament.countryBlackList != ''){
        let auxCountryBlackListArray = this.tournament.countryBlackList.split('|')
        this.countryBlacklistArray = auxCountryBlackListArray.map(countryCode => {
          let countryFound = countries.find(country => country.code === countryCode)
          if(countryFound === undefined){
            this.warningEdits.push(`Country code "${countryCode}" for the "countryBlackList" not found. Might be a wrong country code or not in the countries list. Please check with admin.`)
            // THIS COULD BE A PROBLEM
            return {code: countryCode, name: '?????????'}
          } else {
            return countryFound
          }
        })
      }
      // TODO check this
      if(this.tournament.bikeRestriction != undefined ){
        const auxBikeRestriction = this.tournament.bikeRestriction.toUpperCase()
        let bikeRestrictionPossibleOptions = ['NONE', 'OPEN', 'TEAM', 'PRO TEAM', 'PROVIDED']
        if(bikeRestrictionPossibleOptions.includes(auxBikeRestriction)){
          this.bikeRestriction = auxBikeRestriction
          // if(auxBikeRestriction === 'PRO TEAM'){
          //   this.bikeRestriction = 'Pro Team'
          // } else {
          //   this.bikeRestriction = auxBikeRestriction
          // }
        }
        if(auxBikeRestriction === 'PROVIDED' && this.tournament.bikeProvided !== 0){
          let auxBikeProvidedOption = this.bikes.find(bike => bike.id == this.tournament.bikeProvided)
          if(auxBikeProvidedOption === undefined){
            this.warningEdits.push(`Bike ID "${this.tournament.bikeProvided}" for the "bikeProvided" not found. Might be a wrong bike ID or not in the bikes list. Please check with admin.`)
          } else {
            this.bikeProvidedSelected = auxBikeProvidedOption
            this.bikeProvidedInput = auxBikeProvidedOption.name
          }
        }
        if(this.stringHasDigits(auxBikeRestriction)){
          let auxBikeRestriction = this.tournament.bikeRestriction.split('|')
          this.bikeRestriction = 'SELECT'
          this.bikeRestrictionArray = auxBikeRestriction.map(bikeId => {
            let bikeFound = this.bikes.find(bike => bike.id == bikeId)
            if(bikeFound === undefined){
              this.warningEdits.push(`Bike ID "${bikeId}" for the "bikeRestriction" not found. Might be a wrong bike ID or not in the bikes list. Please check with admin.`)
              // THIS COULD BE A PROBLEM
              return {id: bikeId, name: '?????????'}
            } else {
              return bikeFound
            }
          })
        }
      }
      

      if(this.tournament.isBikeUpgradesAllowed != undefined){
        this.isBikeUpgradesAllowed = this.tournament.isBikeUpgradesAllowed.toString()
      }
      if(this.tournament.isCompulsivesAllowed != undefined){
        this.isCompulsivesAllowed = this.tournament.isCompulsivesAllowed.toUpperCase()
      }
      if(this.tournament.winningCondition != undefined){
        this.winningCondition = this.tournament.winningCondition.toString()
      }
      if(this.tournament.scoreDisplay != undefined){
        this.scoreDisplay = this.tournament.scoreDisplay.toString()
      }
      if(this.tournament.lapLimit != undefined){
        this.lapLimit = this.tournament.lapLimit.toString()
      }
      if(this.tournament.circuitRestriction != undefined){
        let auxCircuitRestriction = this.tournament.circuitRestriction.toUpperCase()
        if(auxCircuitRestriction === 'RANDOM'){
          this.selectedOptionCircuitRestriction = 'RANDOM'
        } else if(auxCircuitRestriction === ''){
          this.selectedOptionCircuitRestriction = 'NONE'
        } else if(this.stringHasDigits(this.tournament.circuitRestriction)){
          this.selectedOptionCircuitRestriction = 'LIMITED'
          let auxCircuitRestrictionArray = this.tournament.circuitRestriction.split('|')
          this.circuitRestrictionArray = auxCircuitRestrictionArray.map(circuitId => {
            let circuitFound = this.circuits.find(circuit => circuit.id == circuitId)
            if(circuitFound === undefined){
              this.warningEdits.push(`Circuit ID "${circuitId}" for the "circuitRestriction" not found. Might be a wrong circuit ID or not in the circuits list. Please check with admin.`)
              // THIS COULD BE A PROBLEM
              return {id: circuitId, name: '?????????'}
            } else {
              return circuitFound
            }
          })
        }
      }
      if(this.tournament.playerStartingPosition != undefined){
        this.playerStartingPosition = this.tournament.playerStartingPosition.toString()
      }
      if(this.tournament.opponentsLimit != undefined){
        this.opponentsLimit = this.tournament.opponentsLimit.toString()
      }
      if(this.tournament.playerMinLevel != undefined){
        this.playerMinLevel = this.tournament.playerMinLevel.toString()
      }
      if(this.tournament.playerMaxLevel != undefined){
        this.playerMaxLevel = this.tournament.playerMaxLevel.toString()
      }
      if(this.tournament.bikeMinLevel != undefined){
        this.bikeMinLevel = this.tournament.bikeMinLevel.toString()
      }
      if(this.tournament.bikeMaxLevel != undefined){
        this.bikeMaxLevel = this.tournament.bikeMaxLevel.toString()
      }
      if(this.tournament.playerMinRace != undefined){
        this.playerMinRace = this.tournament.playerMinRace.toString()
      }
      if(this.tournament.playerMaxRace != undefined){
        this.playerMaxRace = this.tournament.playerMaxRace.toString()
      }
      if(this.tournament.entryCost != undefined){
        this.entryCost = this.tournament.entryCost.toString()
      }
      if(this.tournament.entryCurrency != undefined){
        this.entryCurrency = this.tournament.entryCurrency.toString()
      }
      if(this.tournament.isEntryFeeOnlyOnce != undefined){
        this.isEntryFeeOnlyOnce = this.tournament.isEntryFeeOnlyOnce.toString()
      }

      // prize class 1
      if(this.tournament.prizeClass1ID !== undefined){
        this.prizeClass1ID = this.tournament.prizeClass1ID.toString()
        if(this.tournament.prizeClass1ID === 3){
          let auxPrizeClass1BikeOption = this.bikes.find(bike => bike.id == this.tournament.prizeClass1Number)
          if(auxPrizeClass1BikeOption === undefined){
            this.warningEdits.push(`Bike ID "${this.tournament.prizeClass1Number}" for the "prizeClass1Number" not found. Might be a wrong bike ID or not in the bikes list. Please check with admin.`)
          } else {
            this.prizeClass1BikeSelected = auxPrizeClass1BikeOption
            this.prizeClass1BikeInput = auxPrizeClass1BikeOption.name
          }
        }
        if(this.tournament.prizeClass1ID === 4 && this.tournament.prizeClass1URL){
          this.prizeClass1URL = this.tournament.prizeClass1URL
        }
      }
      if(this.tournament.prizeClass1Desc !== undefined) {
        if(this.tournament.prizeClass1Desc === ''){
          this.prizeClass1DescInput = ''
        } else if (this.tournament.prizeClass1Desc[0] === '*'){
          this.prizeClass1DescInput = this.tournament.prizeClass1Desc.slice(1)
        } else {
          let prizeClass1DescOption = this.strings.find(string => string.name === this.tournament.prizeClass1Desc)
          if(prizeClass1DescOption === undefined){
            this.warningEdits.push(`String "${this.tournament.prizeClass1Desc}" for the "prizeClass1Desc" not found. Might be a wrong string or not in the strings list. Please check with admin.`)
          } else {
            this.prizeClass1DescSelected = prizeClass1DescOption
            this.prizeClass1DescInput = prizeClass1DescOption.value
          }
        }
      }
      if(this.tournament.prizeClass1Number !== undefined){
        this.prizeClass1Number = this.tournament.prizeClass1Number.toString()
      }
      if(this.tournament.prizeClass1Rank !== undefined){
        this.prizeClass1Rank = this.tournament.prizeClass1Rank.toString()
      }

      // prize class 2
      if(this.tournament.prizeClass2ID !== undefined){
        this.prizeClass2ID = this.tournament.prizeClass2ID.toString()
        if(this.tournament.prizeClass2ID === 3){
          let auxPrizeClass2BikeOption = this.bikes.find(bike => bike.id == this.tournament.prizeClass2Number)
          if(auxPrizeClass2BikeOption === undefined){
            this.warningEdits.push(`Bike ID "${this.tournament.prizeClass2Number}" for the "prizeClass2Number" not found. Might be a wrong bike ID or not in the bikes list. Please check with admin.`)
          } else {
            this.prizeClass2BikeSelected = auxPrizeClass2BikeOption
            this.prizeClass2BikeInput = auxPrizeClass2BikeOption.name
          }
        }
        if(this.tournament.prizeClass2ID === 4){
          this.prizeClass2URL = this.tournament.prizeClass2URL
        }
      }
      if(this.tournament.prizeClass2Desc !== undefined) {
        if(this.tournament.prizeClass2Desc === ''){
          this.prizeClass2DescInput = ''
        } else if (this.tournament.prizeClass2Desc[0] === '*'){
          this.prizeClass2DescInput = this.tournament.prizeClass2Desc.slice(1)
        } else {
          let prizeClass2DescOption = this.strings.find(string => string.name === this.tournament.prizeClass2Desc)
          if(prizeClass2DescOption === undefined){
            this.warningEdits.push(`String "${this.tournament.prizeClass2Desc}" for the "prizeClass2Desc" not found. Might be a wrong string or not in the strings list. Please check with admin.`)
          } else {
            this.prizeClass2DescSelected = prizeClass2DescOption
            this.prizeClass2DescInput = prizeClass2DescOption.value
          }
        }
      }
      if(this.tournament.prizeClass2Number !== undefined){
        this.prizeClass2Number = this.tournament.prizeClass2Number.toString()
      }
      if(this.tournament.prizeClass2Rank !== undefined){
        this.prizeClass2Rank = this.tournament.prizeClass2Rank.toString()
      }

      // prize class 3
      if(this.tournament.prizeClass3ID !== undefined){
        this.prizeClass3ID = this.tournament.prizeClass3ID.toString()
        if(this.tournament.prizeClass3ID === 3){
          let auxPrizeClass3BikeOption = this.bikes.find(bike => bike.id == this.tournament.prizeClass3Number)
          if(auxPrizeClass3BikeOption === undefined){
            this.warningEdits.push(`Bike ID "${this.tournament.prizeClass3Number}" for the "prizeClass3Number" not found. Might be a wrong bike ID or not in the bikes list. Please check with admin.`)
          } else {
            this.prizeClass3BikeSelected = auxPrizeClass3BikeOption
            this.prizeClass3BikeInput = auxPrizeClass3BikeOption.name
          }
        }
        if(this.tournament.prizeClass3ID === 4){
          this.prizeClass3URL = this.tournament.prizeClass3URL
        }
      }
      if(this.tournament.prizeClass3Desc !== undefined) {
        if(this.tournament.prizeClass3Desc === ''){
          this.prizeClass3DescInput = ''
        } else if (this.tournament.prizeClass3Desc[0] === '*'){
          this.prizeClass3DescInput = this.tournament.prizeClass3Desc.slice(1)
        } else {
          let prizeClass3DescOption = this.strings.find(string => string.name === this.tournament.prizeClass3Desc)
          if(prizeClass3DescOption === undefined){
            this.warningEdits.push(`String "${this.tournament.prizeClass3Desc}" for the "prizeClass3Desc" not found. Might be a wrong string or not in the strings list. Please check with admin.`)
          } else {
            this.prizeClass3DescSelected = prizeClass3DescOption
            this.prizeClass3DescInput = prizeClass3DescOption.value
          }
        }
      }
      if(this.tournament.prizeClass3Number !== undefined){
        this.prizeClass3Number = this.tournament.prizeClass3Number.toString()
      }
      if(this.tournament.prizeClass3Rank !== undefined){
        this.prizeClass3Rank = this.tournament.prizeClass3Rank.toString()
      }

      // sponsor name
      if(this.tournament.sponsorName !== undefined) {
        if(this.tournament.sponsorName === ''){
          this.sponsorNameInput = ''
        } else if (this.tournament.sponsorName[0] === '*'){
          this.sponsorNameInput = this.tournament.sponsorName.slice(1)
        } else {
          let sponsorNameOption = this.strings.find(string => string.name === this.tournament.sponsorName)
          if(sponsorNameOption === undefined){
            this.warningEdits.push(`String "${this.tournament.sponsorName}" for the "sponsorName" not found. Might be a wrong string or not in the strings list. Please check with admin.`)
          } else {
            this.sponsorNameSelected = sponsorNameOption
            this.sponsorNameInput = sponsorNameOption.value
          }
        }
      }

      // sponsor slogan
      if(this.tournament.sponsorSlogan !== undefined) {
        if(this.tournament.sponsorSlogan === ''){
          this.sponsorSloganInput = ''
        } else if (this.tournament.sponsorSlogan[0] === '*'){
          this.sponsorSloganInput = this.tournament.sponsorSlogan.slice(1)
        } else {
          let sponsorSloganOption = this.strings.find(string => string.name === this.tournament.sponsorSlogan)
          if(sponsorSloganOption === undefined){
            this.warningEdits.push(`String "${this.tournament.sponsorSlogan}" for the "sponsorSlogan" not found. Might be a wrong string or not in the strings list. Please check with admin.`)
          } else {
            this.sponsorSloganSelected = sponsorSloganOption
            this.sponsorSloganInput = sponsorSloganOption.value
          }
        }
      }

      if(this.tournament.filterable != undefined){
        this.filterable = this.tournament.filterable.toString()
      }
      if(this.tournament.rulesModification != undefined){
        if(this.tournament.rulesModification.toLowerCase() === 'none'){
          this.rulesModification = 'NONE'
        } else {
          this.rulesModification = this.tournament.rulesModification.toString()
        }
      }
      if(this.tournament.helpshiftLinkDisplay != undefined){
        this.helpshiftLinkDisplay = this.tournament.helpshiftLinkDisplay.toString()
      }
      if(this.tournament.sortOrder != undefined){
        this.sortOrder = this.tournament.sortOrder.toString()
      }
      if(this.tournament.minIAP != undefined){
        this.minIAP = this.tournament.minIAP.toString()
      }
      if(this.tournament.worstDivision != undefined){
        this.worstDivision = this.tournament.worstDivision.toString()
      }
      if(this.tournament.bestDivision != undefined){
        this.bestDivision = this.tournament.bestDivision.toString()
      }

      setTimeout(() => {
        this.initializated = true
      }, 3000)
    },
    updateRefs() {
      // This is required to update the refs after the component is loaded
      // If this is not set, when editing the field, the selected value will -still- be
      // the old one, not the new one... blame <b-autocomplete> for this
      if(this.nameSelected) {
        this.$refs.nameAutocomplete.setSelected(this.nameSelected)
      }
      if(this.bikeProvidedSelected && this.bikeRestriction === 'PROVIDED'){
        this.$refs.bikeProvidedAutocomplete.setSelected(this.bikeProvidedSelected)
      }
      if(this.prizeClass1DescSelected && this.prizeClass1ID !== '0'){
        this.$refs.prizeClass1DescAutocomplete.setSelected(this.prizeClass1DescSelected)
      }
      if(this.prizeClass2DescSelected && this.prizeClass2ID !== '0'){
        this.$refs.prizeClass2DescAutocomplete.setSelected(this.prizeClass2DescSelected)
      }
      if(this.prizeClass3DescSelected && this.prizeClass3ID !== '0'){
        this.$refs.prizeClass3DescAutocomplete.setSelected(this.prizeClass3DescSelected)
      }
      if(this.prizeClass1BikeSelected && this.prizeClass1ID === '3'){
        this.$refs.prizeClass1BikeAutocomplete.setSelected(this.prizeClass1BikeSelected)
      }
      if(this.prizeClass2BikeSelected && this.prizeClass2ID === '3'){
        this.$refs.prizeClass2BikeAutocomplete.setSelected(this.prizeClass2BikeSelected)
      }
      if(this.prizeClass3BikeSelected && this.prizeClass3ID === '3'){
        this.$refs.prizeClass3BikeAutocomplete.setSelected(this.prizeClass3BikeSelected)
      }
      if(this.sponsorNameSelected){
        this.$refs.sponsorNameAutocomplete.setSelected(this.sponsorNameSelected)
      }
      if(this.sponsorSloganSelected){
        this.$refs.sponsorSloganAutocomplete.setSelected(this.sponsorSloganSelected)
      }
    },
    getMimeType(file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
          header += byteArray[i].toString(16);
        }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    },
		uploadPrizeImage(event, prizeID) {
			// Reference to the DOM input element
			const { files } = event.target;
			// Ensure that you have a file before attempting to read it
			if (files && files[0]) {
				// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
				if (this[`prizeClass${prizeID}Image`].src) {
					URL.revokeObjectURL(this[`prizeClass${prizeID}Image`].src)
				}
				// 2. Create the blob link to the file to optimize performance:
				const blob = URL.createObjectURL(files[0]);
				
				// 3. The steps below are designated to determine a file mime type to use it during the 
				// getting of a cropped image from the canvas. You can replace it them by the following string, 
				// but the type will be derived from the extension and it can lead to an incorrect result:
				//
				// this.image = {
				//    src: blob;
				//    type: files[0].type
				// }
				
				// Create a new FileReader to read this image binary data
				const reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.image" refers to the image of Vue component
					this[`prizeClass${prizeID}Image`] = {
						// Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
						src: blob,
						// Determine the image type to preserve it during the extracting the image from canvas:
						type: this.getMimeType(e.target.result, files[0].type),
					};
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsArrayBuffer(files[0]);
			}
		},
    cropPrizeClassImage(prizeID) {
      const result = this.$refs[`cropperPrize${prizeID}Image`].getResult();
      const resultData = result.canvas.toDataURL("image/png")
      this[`prizeClass${prizeID}ImageResultData`] = resultData;
      this[`prizeClass${prizeID}Image`] = {
        src: null,
        type: null
      }
    },
    pushCountryWhitelist(country){
      if(!country) return
      this.countryBlacklistArray = this.countryBlacklistArray.filter(blacklistCountry => blacklistCountry.code !== country.code);
      if(this.countryWhitelistArray.find(whitelistCountry => whitelistCountry.code === country.code)) {
        return
      }
      // if the country is already in the blacklist, remove it
      this.countryWhitelistArray.push(country);
      this.countryWhitelistInput = '';
    },
    pushCountryBlacklist(country){
      if(!country) return
      // if the country is already in the whitelist, remove it
      this.countryWhitelistArray = this.countryWhitelistArray.filter(whitelistCountry => whitelistCountry.code !== country.code);
      // if the country is already in the blacklist, skip
      if(this.countryBlacklistArray.find(blacklistCountry => blacklistCountry.code === country.code)) {
        return
      }
      this.countryBlacklistArray.push(country);
      this.countryBlacklistInput = '';
    },
    pushBikeRestrictionArray(bike){
      if(!bike) return
      // if the bike is already in the array, skip
      if(this.bikeRestrictionArray.find(bikeRestriction => bikeRestriction.id === bike.id)){
        return
      }
      this.bikeRestrictionArray.push(bike);
      this.bikeRestrictionInput = '';
    },
    // pushBikeProvidedArray(bike){
    //   if(!bike) return
    //   // if the bike is already in the array, skip
    //   if(this.bikeProvidedArray.find(bikeProvided => bikeProvided.id === bike.id)){
    //     return
    //   }
    //   this.bikeProvidedArray.push(bike);
    //   this.bikeProvidedInput = '';
    // },
    pushCircuitRestrictionArray(circuit){
      if(!circuit) return
      // if the circuit is already in the array, remove it
      if(this.circuitRestrictionArray.find(circuitRestriction => circuitRestriction.id === circuit.id)){
        return
      }
      this.circuitRestrictionArray.push(circuit);
      this.circuitRestrictionInput = '';
    },
    selectPrizeClass1Bike(bike){
      // if(!bike) return
      this.prizeClass1BikeSelected = bike;
      this.prizeClass1Number = bike ? bike.id : null;
    },
    selectPrizeClass2Bike(bike){
      // if(!bike) return
      this.prizeClass2BikeSelected = bike;
      this.prizeClass2Number = bike ? bike.id : null;
    },
    selectPrizeClass3Bike(bike){
      // if(!bike) return
      this.prizeClass3BikeSelected = bike;
      this.prizeClass3Number = bike ? bike.id : null;
    },
    removeCountryFromWhitelist(countryCode){
      this.countryWhitelistArray = this.countryWhitelistArray.filter(country => country.code !== countryCode);
    },
    removeCountryFromBlacklist(countryCode){
      this.countryBlacklistArray = this.countryBlacklistArray.filter(country => country.code !== countryCode);
    },
    removeBikeFromRestriction(bikeId){
      this.bikeRestrictionArray = this.bikeRestrictionArray.filter(bike => bike.id !== bikeId);
    },
    removeCircuitFromRestriction(circuitId){
      this.circuitRestrictionArray = this.circuitRestrictionArray.filter(circuit => circuit.id !== circuitId);
    },
  },
  computed: {
    apiUrl: function() {
      return process.env.VUE_APP_API_URL
    },
    myTimezone: function() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    filteredNameStrings: function () {
      return this.strings.filter(string => {
        return string.value.toLowerCase().indexOf(this.nameInput.toLowerCase()) >= 0
      })
    },
    filteredCountriesWhitelist: function () {
      return countries.filter(country => {
        return country.name.toLowerCase().indexOf(this.countryWhitelistInput.toLowerCase()) >= 0
      })
    },
    filteredCountriesBlacklist: function () {
      return countries.filter(country => {
        return country.name.toLowerCase().indexOf(this.countryBlacklistInput.toLowerCase()) >= 0
      })
    },
    filteredBikeRestriction: function () {
      return this.bikes.filter(bike => {
        return bike.name.toLowerCase().indexOf(this.bikeRestrictionInput.toLowerCase()) >= 0
      })
    },
    filteredBikeProvided: function () {
      return this.bikes.filter(bike => {
        return bike.name.toLowerCase().indexOf(this.bikeProvidedInput.toLowerCase()) >= 0
      })
    },
    filteredCircuitRestriction: function () {
      return this.circuits.filter(circuit => {
        return circuit.name.toLowerCase().indexOf(this.circuitRestrictionInput.toLowerCase()) >= 0
      })
    },
    filteredStringsPrizeClass1Desc: function () {
      return this.strings.filter(string => {
        return string.value.toLowerCase().indexOf(this.prizeClass1DescInput.toLowerCase()) >= 0
      })
    },
    filteredStringsPrizeClass2Desc: function () {
      return this.strings.filter(string => {
        return string.value.toLowerCase().indexOf(this.prizeClass2DescInput.toLowerCase()) >= 0
      })
    },
    filteredStringsPrizeClass3Desc: function () {
      return this.strings.filter(string => {
        return string.value.toLowerCase().indexOf(this.prizeClass3DescInput.toLowerCase()) >= 0
      })
    },
    filteredPrizeClass1Bike: function () {
      return this.bikes.filter(bike => {
        return bike.name.toLowerCase().indexOf(this.prizeClass1BikeInput.toLowerCase()) >= 0
      })
    },
    filteredPrizeClass2Bike: function () {
      return this.bikes.filter(bike => {
        return bike.name.toLowerCase().indexOf(this.prizeClass2BikeInput.toLowerCase()) >= 0
      })
    },
    filteredPrizeClass3Bike: function () {
      return this.bikes.filter(bike => {
        return bike.name.toLowerCase().indexOf(this.prizeClass3BikeInput.toLowerCase()) >= 0
      })
    },
    filteredSponsorNameStrings: function () {
      return this.strings.filter(string => {
        return string.value.toLowerCase().indexOf(this.sponsorNameInput.toLowerCase()) >= 0
      })
    },
    filteredSponsorSloganStrings: function () {
      return this.strings.filter(string => {
        return string.value.toLowerCase().indexOf(this.sponsorSloganInput.toLowerCase()) >= 0
      })
    },
  },
  watch: {
    bikeRestriction: function (newVal, oldVal) {
      // if the bike restriction changes, reset the bike restriction array
      if(!this.initializated) return 
      if(newVal !== oldVal){
        this.bikeProvidedSelected = null
        this.bikeProvidedInput = ''
      }
    },
    prizeClass1ID: function (newVal, oldVal) {
      // if the prize changes, reset prizeClass1Number, prizeClass1URL
      if(!this.initializated) return
      if(newVal !== oldVal){
        this.prizeClass1Number = null;
        // this.prizeClass1URL = null;
        this.prizeClass1BikeInput = ''
        this.prizeClass1BikeSelected = null
        this.prizeClass1Image = {
          src: null,
          type: null
        },
        this.prizeClass1ImageLoaded = false
        this.prizeClass1ImageResultData = null
      }
    },
    prizeClass2ID: function (newVal, oldVal) {
      // if the prize changes, reset prizeClass1Number, prizeClass1URL
      if(!this.initializated) return
      if(newVal !== oldVal){
        this.prizeClass2Number = null;
        // this.prizeClass2URL = null;
        this.prizeClass2BikeInput = ''
        this.prizeClass2BikeSelected = null
        this.prizeClass2Image = {
          src: null,
          type: null
        },
        this.prizeClass2ImageLoaded = false
        this.prizeClass2ImageResultData = null
      }
    },
    prizeClass3ID: function (newVal, oldVal) {
      // if the prize changes, reset prizeClass1Number, prizeClass1URL
      if(!this.initializated) return
      if(newVal !== oldVal){
        this.prizeClass3Number = null;
        // this.prizeClass3URL = null;
        this.prizeClass3BikeInput = ''
        this.prizeClass3BikeSelected = null
        this.prizeClass3Image = {
          src: null,
          type: null
        },
        this.prizeClass3ImageLoaded = false
        this.prizeClass3ImageResultData = null
      }
    }
  }
}

</script>

<style lang="scss">
.prize-cropper{
  max-width: 400px;
  border: 5px solid rgb(226, 102, 0);
  background: #FFF;
}
.cropper-background{
  background: #FFF;
}
.notification-cropper{
  max-width: 400px;
  background-color: rgb(226, 102, 0);
  padding: 7px 5px 0px 5px;
  text-align: center;
  color: white;
  animation: flash 1.2s infinite;
  @keyframes flash {
    0% {
      color: rgb(226, 102, 0);
    }
    50% {
      color: white;
    }
    100% {
      color: rgb(226, 102, 0);
    }
  }
}
</style>