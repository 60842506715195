<template>
  <div class="section">
    <div class="container">
      <h1 class="title is-3"><i class="fas fa-trophy fa-fw"></i><i class="fas fa-table fa-fw"></i>&nbsp;Tournament Instance - Overview</h1>
      <h2 class="subtitle is-6 is-italic has-text-grey">Tournament ID: {{ idTournament }} / Tournament Instance: {{ idInstance }}</h2>
      <div class="notification" v-if="isLoading">
        <p><i class="fas fa-sync fa-spin fa-lg fa-fw"></i> Loading tournament instance...</p>
      </div>
      <div class="notification is-warning" v-if="errorMessage">
        <p><i class="fas fa-exclamation-triangle fa-lg"></i> There was an error loading the tournament instance. Please try again later.</p>
        <pre class="mt-3">{{ errorMessage }}</pre>
      </div>
      <div v-if="tournamentInstance">
        <hr>
        <InstanceData :tournamentInstance="tournamentInstance" :key="`tournament-${tournamentInstance.instanceId}-data`"/>
        <hr>
        <h1 class="title is-4"><i class="fas fa-table"></i>&nbsp;Leaderboard</h1>
        <div v-if="notFinished">
          <p><i class="fas fa-exclamation-triangle fa-lg"></i> Instance is currently running. No winners have been awarded yet. This table might constantly change.</p>
        </div>
        <InstanceLeaderboard :idTournament="idTournament" :idInstance="idInstance" :tournamentInstance="tournamentInstance" :key="`tournament-${tournamentInstance.instanceId}-leaderboard`"/>
        <hr>
        <h1 class="title is-4"><i class="fas fa-envelope"></i>&nbsp;Notifications sent</h1>
        <div v-if="notFinished">
          <p><i class="fas fa-exclamation-triangle fa-lg"></i> There are no winners yet for this tournament instance.</p>
        </div>
        <div v-else>
          <InstanceNotifications :idTournament="idTournament" :idInstance="idInstance" :tournamentInstance="tournamentInstance" :key="`tournament-${tournamentInstance.instanceId}-standings`"/>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// from vue router it recieves idTournament and idInstance
import InstanceData from '@/components/reports/tournaments/instances/InstanceData.vue'
import InstanceNotifications from '@/components/reports/tournaments/instances/InstanceNotifications.vue'
import InstanceLeaderboard from '@/components/reports/tournaments/instances/InstanceLeaderboard.vue'

export default {
  name: 'InstanceOverview',
  components: {
    InstanceData,
    InstanceLeaderboard,
    InstanceNotifications
  },
  data() {
    return {
      idTournament: this.$route.params.idTournament,
      idInstance: this.$route.params.idInstance,
      isLoading: true,
      notFinished: false,
      errorMessage: '',
      tournamentInstance: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$http.get(`/tournaments/${this.$route.params.idTournament}/instances/${this.$route.params.idInstance}`)
      .then(response => {
        const tournamentInstance = response.data;
        // make the name readable
        tournamentInstance.readableName = this.getString(tournamentInstance.name);
        this.tournamentInstance = tournamentInstance;
        // fetch the instance
        if(tournamentInstance.hasAwardedWinners == 0) {
          // there are no winners so we can show a warning
          this.notFinished = true;
        }
        this.isLoading = false;
      })
      .catch(error => {
        this.showErrorNotification('There was an error loading the tournament instance. Please try again later.');
        this.errorMessage = error.message
        this.isLoading = false;
        console.error(error);
      })
    },
  },
}
</script>