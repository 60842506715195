<template>
  <section class="hero is-white is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title is-4">
          🤔 Lost?
        </h1>
        <h1 class="subtitle is-6">
          This page doesn't exist
        </h1>
        <router-link :to="{name: 'tournaments'}" class="button is-dark is-small">
          <i class="fas fa-reply"></i>&nbsp;&nbsp;Back home
        </router-link>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'ForbiddenComponent',
  computed: {
    userRole(){
      return this.$store.getters.getLoggedUserRole;
    }
  }
};
</script>