<template>
  <div>
    <h1 class="title is-4 mt-5">Tournament instances</h1>
    <h5 class="subtitle is-6 is-italic has-text-grey">
      For tournament <span class="has-text-primary">{{ tournament.readableName }}</span>
    </h5>
    <div class="content">
      <p class="has-text-grey"><u>Note</u>: Some tournaments have rounds with multiple instances, for errors during their initializations or other issues.<br/>Other tournament instances could <u>also</u> be in progress (no winners yet..)</p>
      <p>Total count of instances: <span v-if="isLoading" class="is-italic">Loading...</span><span v-else><b>{{ instances.length }}</b> instances</span></p>
    </div>
    <b-table
      class="vertical-aligned" 
      :data="instances"
      :loading="isLoading"
      hoverable
      bordered
      narrowed
      striped
      page-length="10"
      paginated
    >
    <b-table-column field="roundNumber" centered label="Round" v-slot="props">
      {{ props.row.roundNumber }}  
    </b-table-column>
    <b-table-column field="instanceId" label="Instance ID" v-slot="props">
      <p class="is-size-7 is-family-monospace">{{ props.row.instanceId }}</p>  
    </b-table-column>
    <b-table-column field="hasAwardedWinners" centered label="Winners" v-slot="props">
      <div v-if="props.row.hasAwardedWinners">
        <b-tooltip label="Winners have been awarded">
          <i class="fas fa-trophy has-text-success fa-lg"></i>
        </b-tooltip>
      </div>
      <div v-else>
        <b-tooltip label="Winners have not been awarded yet">
          <i class="far fa-clock has-text-danger fa-lg"></i>
        </b-tooltip>
      </div>
    </b-table-column>
    <b-table-column field="numEntrants" centered label="Entries" v-slot="props">
      {{ props.row.numEntrants }}
    </b-table-column>
    <b-table-column label="Top User" v-slot="props">
      <div class="is-flex is-flex-direction-row is-align-items-center" >
        <figure class="image is-32x32 mr-3" >
          <img class="is-rounded" :src="props.row.user.profile_picture" alt="avatar" width="32" height="32" v-if="props.row.user && props.row.user.profile_picture">
          <!-- black circle -->
          <div class="is-rounded" style="width: 32px; height: 32px; background-color: #CACACA; border-radius: 32px" v-else></div>
        </figure>
        <div v-if="props.row.user">
          <p class="has-text-weight-bold">{{ props.row.user.name }} <a @click="openUserModal(props.row.user.id)"><i class="fas fa-magnifying-glass is-size-7 fa-fw"></i></a></p>
          <p class="is-size-7 is-family-monospace">ID: {{ props.row.user.id }}</p>
        </div>
        <div v-else>
          <p class="is-italic">No top user or no data</p>
          <p class="is-size-7 is-family-monospace">ID: --------</p>
        </div>
      </div>
    </b-table-column>
    <b-table-column field="topUserScore" centered label="Top User Score" v-slot="props">
      {{ formatNumber(props.row.topUserScore) }}
    </b-table-column>
    <b-table-column label="Round Dates" centered v-slot="props">
      <p class="is-size-7">{{ props.row.startTimestamp }} <span class="has-text-grey">(UTC+08)</span></p>
      <p class="is-size-7">{{ props.row.endTimestamp }} <span class="has-text-grey">(UTC+08)</span></p>
    </b-table-column>
    <b-table-column label="Actions" centered v-slot="props">
      <router-link :to="{ name: 'reports-tournaments-instances-overview', params: { idTournament: tournament.id, idInstance: props.row.instanceId }}" class="is-size-7">Overview&nbsp;<i class="fas fa-arrow-right"></i></router-link>
    </b-table-column>
    <template #empty>
      <div class="has-text-centered" v-if="!isLoading">No records found...</div>
      <div class="has-text-centered" v-else>Loading...</div>
    </template>
    </b-table>
  </div>
</template>

<script>
import ModalUserData from '@/components/ModalUserData.vue'

export default {
  name: 'InstancesTable',
  props: {
    tournament: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      instances: [],
      selectedInstance: null,
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    getInitialData() {
      this.isLoading = true;
      this.$http.get(`/tournaments/${this.tournament.id}/instances`)
      .then(response => {
        const instances = response.data;
        instances.forEach(instance => {
          instance.readableName = this.getString(instance.name);
        });
        this.instances = instances;
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
    instanceSelected(instance){
      this.selectedInstance = instance
    },
    openUserModal(userId) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalUserData,
        trapFocus: true,
        props: {
          userId
        },
      })
    }
  }
}

</script>