<template>
   <div class="section">
    <div class="container">
      <h1 class="title is-3"><i class="fas fa-table fa-fw"></i>&nbsp;Tournament Instances</h1>
      <h2 class="subtitle is-6 is-italic has-text-grey">Select a tournament to list the instances</h2>
      <SelectTournament class="mb-5" @select="tournamentSelected"/>
      <div v-if="selectedTournament">
        <hr>
        <h1 class="title is-4">Tournament definition</h1>
        <h2 class="subtitle is-6 is-italic has-text-grey">General information about the tournament definition</h2>
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth is-bordered is-narrow">
              <tbody>
                <tr>
                  <th>Tournament Name</th>
                  <td>{{ selectedTournament.readableName }}</td>
                </tr>
                <tr>
                  <th>Tournament Name Code</th>
                  <td class="is-family-monospace">{{ selectedTournament.name }}</td>
                </tr>
                <tr>
                  <th>Is active?</th>
                  <td>{{ selectedTournament.active ? 'Yes 🟢' : 'No 🔴' }}</td>
                </tr>
                <tr>
                  <th>Start time</th>
                  <td>{{ selectedTournament.startTimestamp }} (Asia/Manila)</td>
                </tr>
                <tr>
                  <th>End time</th>
                  <td>{{ selectedTournament.endTimestamp }} (Asia/Manila)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column">
            <table class="table is-fullwidth is-bordered is-narrow">
              <tbody>
                <tr>
                  <th>Time Offset (Hours)</th>
                  <td class="has-text-centered">{{ selectedTournament.timeOffsetHours || '- - -' }}</td>
                </tr>
                <tr>
                  <td colspan="2" class="is-size-7 has-text-grey">Can be between -24 and 24. If empty, by default is 0 (no offset).</td>
                </tr>
                <tr>
                  <th>Restart Frequency</th>
                  <td class="has-text-centered">{{ selectedTournament.restartFrequency }}</td>
                </tr>
                <tr>
                  <td colspan="2" class="is-size-7 has-text-grey">In hours, when a new version of the tournament will start</td>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td class="has-text-centered">{{ selectedTournament.duration }}</td>
                </tr>
                <tr>
                  <td colspan="2" class="is-size-7 has-text-grey">In hours, how long this tournament will run once started. Must be a value equal or greater than 1</td>
                </tr>
                <tr>
                  <th>Last Entry (In minutes)</th>
                  <td class="has-text-centered">{{ selectedTournament.lastEntryInMinutes }}</td>
                </tr>
                <tr>
              <td colspan="2" class="is-size-7 has-text-grey">If empty, by default is 15</td>
            </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr>
        <div class="content">
      </div>
        <InstancesTable class="my-5" :tournament="selectedTournament" :key="`tournament-${selectedTournament.id}-instances`"/>
      </div>
    </div>
  </div>
</template>

<script>
import SelectTournament from '@/components/SelectTournament.vue'
import InstancesTable from '@/components/reports/tournaments/InstancesTable.vue'

export default {
  name: 'ReportStandings',
  data() {
    return {
      isLoading: true,
      isReady: false,
      selectedTournament: null,
    }
  },
  components: {
    SelectTournament,
    InstancesTable
  },
  created() {

  },
  methods: {
    tournamentSelected(tournament){
      this.selectedTournament = tournament
    }
  }
}
</script>