<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="is-clearfix">
            <h1 class="title is-pulled-left"><router-link :to="{name: 'tournaments'}"><i class="fas fa-arrow-left"></i></router-link>&nbsp;Edit tournament</h1>
            <h1 class="title is-pulled-right"><i class="fas fa-pencil"></i>&nbsp;<i class="fas fa-trophy"></i></h1>
          </div>
          <br>
          <div class="notification has-text-centered px-2" v-if="isLoading">
            <div class="block">
              <p class=""><i class="fas fa-spin fa-sync"></i></p>
              <p class="is-size-7">Loading</p>
            </div>
          </div>
          <div class="notification is-warning" v-else-if="!isLoading && cantRender">
            <!-- An error ocurred -->
            <p><i class="fas fa-exclamation-triangle"></i> An error ocurred</p>
          </div>
          <TournamentForm  @submit="submit" :strings="stringList" :bikes="bikeList" :circuits="circuitList" :tournament="tournament" :edit="true" v-else-if="!isLoading && !cantRender && tournament" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TournamentForm from '@/components/TournamentForm.vue'
// import moment from 'moment-timezone'

export default {
  components: {
    TournamentForm
  },
  data() {
    return {
      isLoading: false,
      cantRender: false,
      tournament: null,
      stringList: [],
      bikeList: [],
      circuitList: [],
      originalTournament: null,
    }
  },
  mounted: function() {
    this.getData()
  },
  methods: {
    getData(){
      this.isLoading = true
      this.startLoading()
      const promises = [
        this.$http.get('/stringTable/list'),
        this.$http.get('/bikes/list'),
        this.$http.get('/circuits/list'),
        this.$http.get(`/tournaments/${this.$route.params.id}`)
      ]
      Promise.all(promises)
      .then(responses => {
        this.stringList = responses[0].data
        this.bikeList = responses[1].data
        this.circuitList = responses[2].data
        this.tournament = responses[3].data
      })
      .catch(error => {
        this.cantRender = true
        this.showErrorNotification('Error fetching data from the server...')
        console.error(error)
      })
      .finally(() => {
        this.isLoading = false
        this.stopLoading()
      })
    },
    submit(payload) {
      // We make a copy of the original tournament
      const originalTournament = JSON.parse(JSON.stringify(this.tournament))
      // Fix dates to unix timestamp
      // originalTournament.startTimestamp = ((new Date(originalTournament.startTimestamp)).getTime() / 1000)
      // originalTournament.endTimestamp = ((new Date(originalTournament.endTimestamp)).getTime() / 1000)
      originalTournament.startTimestamp = (new Date(originalTournament.startTimestamp.split(' ')[0] + 'T' + originalTournament.startTimestamp.split(' ')[1] + '+08:00').getTime() / 1000)
      originalTournament.endTimestamp = (new Date(originalTournament.endTimestamp.split(' ')[0] + 'T' + originalTournament.endTimestamp.split(' ')[1] + '+08:00').getTime() / 1000)

      // Get changed values
      const changedValues = this.getChangedValues(originalTournament, payload);
      // debug
      // console.log(changedValues);
      // return null
      // if changedValues is empty, we don't need to do anything
      if (Object.keys(changedValues).length === 0) {
        this.showSuccessNotification('No changes detected')
        return
      }

      // else, submit to server
      this.startLoading()
      this.$http.post(`/tournaments/edit/${this.$route.params.id}`, changedValues).then(() => {
        // console.log(response)
        this.showSuccessNotification('Tournament edited successfully!')
        this.$router.push({name: 'tournaments'})
      }).catch((err) => {
        this.showErrorNotification('An unexpected error occurred. Please try again later.')
        console.error(err)
      }).finally(() => {
        this.stopLoading()
      })
    },
    getChangedValues(original, edited) {
      const changedValues = {};
      for (const key in edited) {
        if (original[key] !== edited[key]) {
          changedValues[key] = edited[key];
        }
      }
      return changedValues;
    }
  }
}
</script>