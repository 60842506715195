<template>
  <div class="section">
    <div class="container is-fluid">
      <div class="is-clearfix mb-4">
        <h1 class="title is-pulled-left">Tournaments</h1>
        <div class="is-pulled-right">
          <div class="buttons">
            <button @click="getTournaments" class="button is-light">
              <i class="fas fa-sync"></i>
            </button>
            <router-link class="button is-primary" :to="{ name: 'tournaments-new' }">
              <i class="fas fa-plus"></i>
            </router-link>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="columns">
        <div class="column is-8">
          <b-field label="Search by name">
            <b-input v-model="searchTerm" type="text" placeholder="Search" @input="debouncedSearch" clearable/>
          </b-field>
        </div>
        <div class="column is-2">
          <!-- Select page size -->
          <b-field label="Page size">
            <b-select v-model="pageSize" :disabled="isLoading" expanded>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <!-- Select timezone -->
          <b-field label="Timezone">
            <b-select v-model="timezone" :disabled="isLoading" expanded>
              <option value="local">Local ({{ myTimezone }})</option>
              <option value="philippines">Philippines (GMT +8)</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="">
        
        <b-table :data="filteredTournaments" :loading="isLoading" :paginated="true" :per-page="pageSize" striped bordered hoverable :pagination-simple="true">
          <b-table-column centered>
          <template v-slot:header="{}">
            <i class="fas fa-edit"></i>
          </template>
          <template v-slot="props">
            <router-link class="has-text-info" :to="{ name: 'tournaments-edit', params: { id: props.row.id } }">
              <i class="fas fa-pencil"></i>
            </router-link>
            </template>
        </b-table-column>
        <b-table-column field="id" sortable label="ID" centered v-slot="props">
          <span class="is-family-code">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column field="name" sortable label="Name" v-slot="props">
          <b-tooltip :label="props.row.name" position="is-right" v-if="props.row.name[0] !== '*'">
            <i class="fas fa-info-circle has-text-link fa-fw"></i>&nbsp;
          </b-tooltip>
          <span :class="props.row.name[0] !== '*' ? 'is-underlined' : 'is-italic'">{{ getString(props.row.name) ?? '❓ (STRING NOT FOUND)'}}</span>
        </b-table-column>
        <b-table-column field="startTimestamp" sortable label="Start" v-slot="props">
          {{ toSelectedTimezone(props.row.startTimestamp) }}
        </b-table-column>
        <b-table-column field="endTimestamp" sortable label="End" v-slot="props">
          {{ toSelectedTimezone(props.row.endTimestamp) }}
        </b-table-column>
        <b-table-column field="timeOffsetHours" sortable centered numeric label="Time Offset" v-slot="props">
          {{ props.row.timeOffsetHours }}
        </b-table-column>
        <b-table-column field="restartFrequency" sortable centered numeric label="Restart Frequency" v-slot="props">
          {{ props.row.restartFrequency }}
        </b-table-column>
        <b-table-column field="duration" sortable centered label="Duration" v-slot="props">
          {{ props.row.duration }}
        </b-table-column>
        <b-table-column field="isActivated" sortable centered label="Activated" v-slot="props">
          <!-- <i class="fas fa-toggle-on is-size-4 has-text-success" v-if="props.row.isActivated"></i>
          <i class="fas fa-toggle-off is-size-4" v-else></i> -->
          <span class="tag is-success has-text-weight-bold" v-if="props.row.isActivated"><i class="fas fa-power-off"></i>&nbsp;ON</span>
          <span class="tag is-dark" v-else><i class="fas fa-power-off"></i>&nbsp;OFF</span>
        </b-table-column>
        <!-- Actions with DELETE -->
        <!-- <b-table-column centered>
          <template v-slot:header="{}">
            <i class="fas fa-trash"></i>
          </template>
          <template v-slot="props">
            <a class="has-text-danger" @click="confirmDeleteTournament(props.row)">
              <i class="fas fa-trash"></i>
            </a>
            </template>
        </b-table-column> -->
        <!-- <b-table-column centered v-slot="props">
          <div class="buttons is-centered">
            <router-link class="has-text-info" :to="{ name: 'tournaments-edit', params: { id: props.row.id } }">
              <i class="fas fa-edit"></i>
            </router-link>
          </div>
        </b-table-column> -->
        <template #empty>
          <p class="is-size-7 has-text-centered py-5 is-family-code">No tournaments</p>
        </template>
      </b-table>
    </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import moment from 'moment-timezone';
// Tournament has email, and role
export default {
  data() {
    return {
      strings: [],
      tournaments: [],
      filteredTournaments: [],
      searchTerm: '',
      debouncedSearch: debounce(this.search, 500),
      isLoading: false,
      pageSize: "25",
      timezone: 'local'
    };
  },
  mounted() {
    this.getTournaments();
  },
  methods: {
    getTournaments() {
      this.isLoading = true;
      const promises = [
        this.$http.get('/tournaments/list'),
        this.$http.get('/stringTable/list')
      ]
      Promise.all(promises).then(responses => {
        this.tournaments = responses[0].data;
        this.filteredTournaments = responses[0].data;
        this.strings = responses[1].data;
        this.searchTerm = ''
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
      })
    },
    getString(name) {
      // if the string starts with * just return the same string
      if (name.startsWith('*')) {
        return name;
      }
      // if the string doesnt start with * find the name in the strings array
      const string = this.strings.find(string => string.name === name);
      // if the string is found return the value
      if (string) {
        return string.value;
      }
      // if the string is not found return the name
      return '???????????';
    },
    // confirmDeleteTournament(tournament) {
    //   this.$buefy.dialog.confirm({
    //     title: 'Delete Tournament',
    //     message: `Are you sure you want to delete tournament <b>${this.getString(tournament.name)}</b> (ID=${tournament.id})?`,
    //     confirmText: 'Delete',
    //     type: 'is-danger',
    //     hasIcon: true,
    //     onConfirm: () => this.deleteTournament(tournament.id) // If user confirms.. delete it
    //   })
    // },
    // deleteTournament(id) {
    //   this.$http.post(`/tournament/delete/${id}`)
    //     .then(() => {
    //       this.showSuccessNotification('Tournament deleted successfully');
    //       this.getTournaments();
    //     }).catch(error => {
    //       this.showErrorNotification('Error deleting tournament');
    //       console.error(error)
    //     })
    // },
    search(){
      if (!this.searchTerm) {
        this.filteredTournaments = this.tournaments;
      }
      this.filteredTournaments = this.tournaments.filter(tournament => {
        return tournament.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    toSelectedTimezone(timestamp) {
      // the timestamp is in Phillipines timezone
      const fixtimestamp =  timestamp.split(' ')[0] + 'T' + timestamp.split(' ')[1] + '+08:00'
      let date = new Date(fixtimestamp)
      if(this.timezone === 'local'){
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      }
      if(this.timezone === 'philippines'){
        return moment(date).tz('Asia/Manila').format('YYYY-MM-DD HH:mm:ss');
      }
    }
  },
  computed: {
    myTimezone: function () {
      return moment.tz.guess();
    }
  },
}
</script>