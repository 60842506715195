<template>
  <div class="section">
    <div class="container">
      <h1 class="title is-3"><i class="fas fa-line-chart"></i>&nbsp;Currency spent per tournament report</h1>
      <div class="content">

        <p>This report shows the amount of coins and diamonds spent in each round of a tournament.</p>
        <div class="message is-primary">
          <div class="message-body">
            Do have in mind that some tournaments have rounds with multiple instances, while the charts are the best way to visualize the data, the table below will show you the details of each instance.
          </div>
        </div>
        <p class="is-italic has-text-grey">Generated at {{ statsTimestamp }}</p>
        <h2 class="subtitle is-6">
          Select a specific tournament to see more information about it.
        </h2>
      </div>
        
      <!-- <h1 class="title is-4">Engagement per tournament</h1> -->
      <!-- <div class="box">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Tournament</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="selectedTournamentId" @change="getTournamentData">
                    <option :value="null" disabled>- Select a tournament -</option>
                    <option v-for="tournament in availableTournamentReports" :key="tournament.id" :value="tournament.id">
                      ID: {{tournament.id}} - {{ tournament.name }}{{ tournament.active ? ' - 🟢 Active' : '' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <SelectTournament class="mb-5" @select="setSelectedTournament" @fetchTournaments="setTournaments" @fetchActiveTournaments="setActiveTournaments"/>

      <hr>
      <section v-if="selectedTournamentId != null && isReportReady" :key="`report-summary-tournament-${selectedTournamentId}`">
        <h1 class="title is-3">
          {{selectedTournamentData.tournamentName}}
        </h1>
        <h5 class="subtitle is-6 is-italic has-text-grey">
          Tournament ID - {{selectedTournamentId}}
        </h5>
        <div class="level box">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Rounds calculated</p>
              <p class="title">{{ selectedTournamentData.instancesCount }}</p>
              <p class="is-size-7 has-text-grey">&nbsp;</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading"><i class="fas fa-coins fa-lg"></i>&nbsp;Coins in total</p>
              <p class="title">{{ formatLargeNumberCompact(selectedTournamentData.totalCoinsPaid) }}</p>
              <p class="is-size-7 has-text-grey">{{formatLargeNumber(selectedTournamentData.totalCoinsPaid)}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading"><i class="fas fa-coins fa-lg"></i>&nbsp;Coin Entries paid</p>
              <p class="title">{{ formatLargeNumberCompact(selectedTournamentData.totalEntriesPaidInCoins) }}</p>
              <p class="is-size-7 has-text-grey">{{formatLargeNumber(selectedTournamentData.totalEntriesPaidInCoins)}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading"><i class="fas fa-gem fa-lg"></i>&nbsp;Diamond in total</p>
              <p class="title">{{ formatLargeNumberCompact(selectedTournamentData.totalDiamondsPaid) }}</p>
              <p class="is-size-7 has-text-grey">{{formatLargeNumber(selectedTournamentData.totalDiamondsPaid)}}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading"><i class="fas fa-gem fa-lg"></i>&nbsp;Diamond entries paid</p>
              <p class="title">{{ formatLargeNumber(selectedTournamentData.totalEntriesPaidInDiamonds) }}</p>
              <p class="is-size-7 has-text-grey">{{selectedTournamentData.totalEntriesPaidInDiamonds}}</p>
            </div>
          </div>
        </div>
        <CurrencySpentChart title="Total of Coins & Diamonds per round" :chartData="selectedTournamentData.currencySpentChartData" :chartMeta="selectedTournamentData.chartMeta" :key="`currency-spent-tournament-${selectedTournamentId}`" />
        <br>
        <CurrencyAccumulatedChart title="Currency Spent Accumulated vs. Paid Entries" :chartData="selectedTournamentData.currencyAcumulatedChartData" :chartMeta="selectedTournamentData.chartMeta" :key="`currency-acc-tournament-${selectedTournamentId}`" />
        <InstancesTable :instancesTableData="selectedTournamentData.instancesTableData" :tournamentName="selectedTournamentData.tournamentName" :tournamentId="selectedTournamentId" :key="`instances-table-tournament-${selectedTournamentId}`" />
      </section>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import CurrencySpentChart from '@/components/reports/currency-spent/CurrencySpent.vue';
import CurrencyAccumulatedChart from '@/components/reports/currency-spent/CurrencyAccumulated.vue';
import InstancesTable from '@/components/reports/currency-spent/InstancesTable.vue';
import SelectTournament from '@/components/SelectTournament.vue'

export default {
  name: 'ReportCurrencySpent',
  components: {
    CurrencySpentChart,
    CurrencyAccumulatedChart,
    InstancesTable,
    SelectTournament
  },
  data() {
    return {
      isLoading: false,
      isReportReady: false,
      tournaments: [],
      activeTournaments: [],
      currencyStats: {},
      statsTimestamp: null,
      availableTournamentReports: [],
      selectedTournament: null,
      selectedTournamentId: null,
      selectedTournamentData: {
        tournamentId: null,
        tournamentName: null,
        tournamentData: {},
        instancesId: [],
        instancesData: {},
        totalEntriesPaidInCoins: 0,
        totalEntriesPaidInDiamonds: 0,
        totalCoinsPaid: 0, // instanceData.$.entryCurrency = 1
        totalDiamondsPaid: 0, // instanceData.$.entryCurrency = 2
        instancesCount: 0,
        instancesTableData: [],
        currencySpentChartData: {
          serieX: {
            rounds: [],
            dates: [],
          },
          serieY: {
            coins: [],
            diamonds: []
          },
        },
        currencyAcumulatedChartData: {
          serieX: {
            rounds: [],
            dates: [],
          },
          serieY: {
            coins: [],
            diamonds: [],
            entriesPaid: []
          },
        },
        chartMeta: {}
      }
    }
  },
  mounted() {
    this.getInitialData();
  },
  methods: {
    getInitialData() {
      this.isLoading = true;
      this.startLoading();
      this.$http.get('/tournaments/currencyStats')
      .then(response => {
        this.currencyStats = response.data;
        this.statsTimestamp = moment(this.currencyStats.timestamp).format('YYYY-MM-DD HH:mm:ss');
        // get the active tournament with the highest number of entries
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
        this.stopLoading();
      })
    },
    setTournaments(tournaments) {
      this.tournaments = tournaments
    },
    setActiveTournaments(activeTournaments) {
      this.activeTournaments = activeTournaments
    },
    getInstancesWithSameRoundNumber(roundNumber) {
      return this.selectedTournamentData.instancesId.filter(instanceId => {
        return instanceId.split('_')[1] === roundNumber;
      });
    },
    getTournamentNameByTournamentId(tournamentId) {
      const tournament = this.tournaments.find(tournament => tournament.id == tournamentId);
      return tournament ? this.getString(tournament.name) : '❓ (STRING NOT FOUND)';
    },
    getEntryCurrencyLabel(currency) {
      switch(currency) {
        case 1:
          return 'Coins';
        case 2:
          return 'Diamonds';
        default:
          return 'Unknown';
      }
    },
    getEntryCurrencyIcon(currency) {
      switch(currency) {
        case 1:
          return '💰';
        case 2:
          return '💎';
        default:
          return '❓';
      }
    },
    formatLargeNumberCompact(number) {
      return new Intl.NumberFormat('en', {
        style: "decimal",
        notation: "compact",
        compactDisplay: "short",
        maximumSignificantDigits: 3
      }).format(number);
    },
    formatLargeNumber(number) {
      return new Intl.NumberFormat('en', {style: "decimal"}).format(number);
    },
    resetSelectedTournamentData() {
      this.selectedTournamentData = {
        tournamentId: null,
        tournamentName: null,
        tournamentData: {},
        instancesId: [],
        instancesData: {},
        totalEntriesPaidInCoins: 0,
        totalEntriesPaidInDiamonds: 0,
        totalCoinsPaid: 0, // instanceData.$.entryCurrency = 1
        totalDiamondsPaid: 0, // instanceData.$.entryCurrency = 2
        instancesCount: 0,
        instancesTableData: [],
        currencySpentChartData: {
          serieX: {
            rounds: [],
            dates: [],
          },
          serieY: {
            coins: [],
            diamonds: []
          },
        },
        currencyAcumulatedChartData: {
          serieX: {
            rounds: [],
            dates: [],
          },
          serieY: {
            coins: [],
            diamonds: [],
            entriesPaid: []
          },
        },
        chartMeta: {}
      }
    },
    setSelectedTournament(tournament){
      this.isReportReady = false;
      this.resetSelectedTournamentData()
      console.log(tournament)
      this.selectedTournament = tournament
      this.selectedTournamentId = tournament.id
      this.calculateTournamentData()
    },
    calculateTournamentData() {
      this.isReportReady = false;
    
      this.selectedTournamentData.tournamentId = this.selectedTournamentId;
      this.selectedTournamentData.tournamentName = this.getTournamentNameByTournamentId(this.selectedTournamentId);
      this.selectedTournamentData.tournamentData = this.tournaments.filter(tournament => tournament.id == this.selectedTournamentId)[0];
      this.selectedTournamentData.instancesId = Object.keys(this.currencyStats.data[this.selectedTournamentId]).sort((a, b) => a - b);
      this.selectedTournamentData.instancesData = this.currencyStats.data[this.selectedTournamentId];
      this.selectedTournamentData.chartMeta = {};
      const rounds = this.selectedTournamentData.instancesId.map(instanceId => {
        // instancesData is an object with the instanceId as key formated like "50_1099_BkSqCqJqo"
        // the round number is "xx_{roundNumber}_xxx"
        return instanceId.split('_')[1];
      });

      let accumulatedCoins = 0
      let accumulatedDiamonds = 0
      let accumulatedEntriesPaid = 0
      let accumulatedEntriesPaidInCoins = 0
      let accumulatedEntriesPaidInDiamonds = 0
      let previousEntriesPaid = 0
      this.selectedTournamentData.instancesTableData = this.selectedTournamentData.instancesId.map((instanceId) => {
        const instanceData =  this.selectedTournamentData.instancesData[instanceId]
        const roundNumber = instanceId.split('_')[1];
        const row = {
          key: instanceId,
          round: roundNumber,
          entryCurrency: instanceData.entryCurrency,
          entryCurrencyLabel: `${this.getEntryCurrencyIcon(instanceData.entryCurrency)} ${this.getEntryCurrencyLabel(instanceData.entryCurrency)}`,
          entryCost: parseInt(instanceData.entryCost || 0),
          entriesPaid: parseInt(instanceData.entriesPaid || 0),
          growEntriesPaid: 0,
          startDatetime: moment.unix(instanceData.startTimestamp).format('YYYY-MM-DD HH:mm:ss'),
          endDatetime: moment.unix(instanceData.endTimestamp).format('YYYY-MM-DD HH:mm:ss'),
          coins: 0,
          diamonds: 0,
          accumulatedCoins: 0,
          accumulatedDiamonds: 0
        }

        // entries
        row.growEntriesPaid = row.entriesPaid - previousEntriesPaid
        previousEntriesPaid = row.entriesPaid
        accumulatedEntriesPaid += row.entriesPaid

        // coins and diamonds
        if(instanceData.entryCurrency === 1) { // coins
          // coins this instance
          row.coins = parseInt(instanceData.entryCost || 0) * parseInt(instanceData.entriesPaid || 0)
          row.accumulatedCoins = accumulatedCoins
          accumulatedCoins += row.coins
          accumulatedEntriesPaidInCoins += row.entriesPaid
        } else if(instanceData.entryCurrency === 2) { // diamonds
          row.diamonds = parseInt(instanceData.entryCost || 0) * parseInt(instanceData.entriesPaid || 0)
          row.accumulatedDiamonds = accumulatedDiamonds
          accumulatedDiamonds += row.diamonds
          accumulatedEntriesPaidInDiamonds += row.entriesPaid
        }

        return row
      })

      this.selectedTournamentData.totalEntriesPaidInCoins = accumulatedEntriesPaidInCoins;
      this.selectedTournamentData.totalEntriesPaidInDiamonds = accumulatedEntriesPaidInDiamonds;
      this.selectedTournamentData.totalCoinsPaid = accumulatedCoins;
      this.selectedTournamentData.totalDiamondsPaid = accumulatedDiamonds;
      this.selectedTournamentData.instancesCount = rounds.length;

      // CURRENCY SPENT CHART DATA
      // ---------------------------

      // const dates = this.selectedTournamentData.instancesId.map(instanceId => {
      //   return moment.unix(this.selectedTournamentData.instancesData[instanceId].endTimestamp).format('YYYY-MM-DD HH:mm:ss');
      // });
      // const entriesPaid = this.selectedTournamentData.instancesId.map(instanceId => {
      //   return parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid) || 0;
      // });
      
      accumulatedCoins = 0
      accumulatedDiamonds = 0
      
      rounds.forEach(round => {
        // get the instancesId of that round (there can be multiople)
        const instancesId = this.getInstancesWithSameRoundNumber(round);
        let roundData = {
          tournamentId: parseInt(this.selectedTournamentId),
          instancesId: instancesId,
          round: round,
          coins: 0,
          diamonds: 0,
          entryCost: 0,
          entryCurrency: this.selectedTournamentData.instancesData[instancesId[0]].entryCurrency,
          entriesPaid: 0,
          accumulatedCoins: 0,
          accumulatedDiamonds: 0,
          accumulatedEntriesPaid: accumulatedEntriesPaid,
          startDatetime: moment.unix(this.selectedTournamentData.instancesData[instancesId[0]].startTimestamp).format('YYYY-MM-DD HH:mm:ss'),
          endDatetime: moment.unix(this.selectedTournamentData.instancesData[instancesId[0]].endTimestamp).format('YYYY-MM-DD HH:mm:ss'),
          merged: instancesId.length > 1 ? true : false,
          mixedCurrencies: false,
          differentEntryCosts: false
        }
        
        instancesId.forEach(instanceId => {
          roundData.entryCost = parseInt(this.selectedTournamentData.instancesData[instanceId].entryCost || 0);
          roundData.entriesPaid += parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid || 0);
          accumulatedEntriesPaid += parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid || 0); 
          roundData.accumulatedEntriesPaid = accumulatedEntriesPaid;
          // sum i
          if(this.selectedTournamentData.instancesData[instanceId].entryCurrency == 1){ // coins = 1
            roundData.coins += parseInt(this.selectedTournamentData.instancesData[instanceId].entryCost || 0) * parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid || 0);
            accumulatedCoins += parseInt(this.selectedTournamentData.instancesData[instanceId].entryCost || 0) * parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid || 0);
            roundData.accumulatedCoins = accumulatedCoins;
          }
          if(this.selectedTournamentData.instancesData[instanceId].entryCurrency == 2){ // diamonds = 2
            roundData.diamonds += parseInt(this.selectedTournamentData.instancesData[instanceId].entryCost || 0) * parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid || 0);
            accumulatedDiamonds += parseInt(this.selectedTournamentData.instancesData[instanceId].entryCost || 0) * parseInt(this.selectedTournamentData.instancesData[instanceId].entriesPaid || 0);
            roundData.accumulatedDiamonds = accumulatedDiamonds;
          }

          roundData.endDatetime = moment.unix(this.selectedTournamentData.instancesData[instanceId].endTimestamp).format('YYYY-MM-DD HH:mm:ss');

          if(this.selectedTournamentData.instancesData[instanceId].entryCurrency != roundData.entryCurrency) {
            roundData.mixedCurrencies = true;
          }
          if(this.selectedTournamentData.instancesData[instanceId].entryCost != roundData.entryCost) {
            roundData.differentEntryCosts = true;
          }
        });
        
        this.selectedTournamentData.chartMeta[round] = roundData;
      });

      // CURRENCY SPENT CHART DATA
      // ---------------------------

      this.selectedTournamentData.currencySpentChartData.serieX.rounds = []
      this.selectedTournamentData.currencySpentChartData.serieX.dates = []
      this.selectedTournamentData.currencySpentChartData.serieY.coins = []
      this.selectedTournamentData.currencySpentChartData.serieY.diamonds = []
      for(const round in this.selectedTournamentData.chartMeta) {
        // SERIE X - rounds
        this.selectedTournamentData.currencySpentChartData.serieX.rounds.push(round)
        // SERIE X - dates
        this.selectedTournamentData.currencySpentChartData.serieX.dates.push(this.selectedTournamentData.chartMeta[round].endDatetime)
        // SERIE Y - coins
        this.selectedTournamentData.currencySpentChartData.serieY.coins.push(this.selectedTournamentData.chartMeta[round].coins)
        // SERIE Y - diamonds
        this.selectedTournamentData.currencySpentChartData.serieY.diamonds.push(this.selectedTournamentData.chartMeta[round].diamonds)
      }

      // CURRENCY ACCUMULATED CHART DATA
      // ---------------------------

      this.selectedTournamentData.currencyAcumulatedChartData.serieX.rounds = []
      this.selectedTournamentData.currencyAcumulatedChartData.serieX.dates = []
      this.selectedTournamentData.currencyAcumulatedChartData.serieY.coins = []
      this.selectedTournamentData.currencyAcumulatedChartData.serieY.diamonds = []
      this.selectedTournamentData.currencyAcumulatedChartData.serieY.entriesPaid = []
      for(const round in this.selectedTournamentData.chartMeta) {
        // SERIE X - rounds
        this.selectedTournamentData.currencyAcumulatedChartData.serieX.rounds.push(round)
        // SERIE X - dates
        this.selectedTournamentData.currencyAcumulatedChartData.serieX.dates.push(this.selectedTournamentData.chartMeta[round].endDatetime)
        // SERIE Y - coins
        this.selectedTournamentData.currencyAcumulatedChartData.serieY.coins.push(this.selectedTournamentData.chartMeta[round].accumulatedCoins)
        // SERIE Y - diamonds
        this.selectedTournamentData.currencyAcumulatedChartData.serieY.diamonds.push(this.selectedTournamentData.chartMeta[round].accumulatedDiamonds)
        // SERIE Y - entriesPaid
        this.selectedTournamentData.currencyAcumulatedChartData.serieY.entriesPaid.push(this.selectedTournamentData.chartMeta[round].entriesPaid)
      }

      this.isReportReady = true;
      
    }
  }
}
</script>