<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-6">
          <div class="is-clearfix">
            <h1 class="title is-pulled-left"><router-link :to="{name: 'users'}"><i class="fas fa-arrow-left"></i></router-link>&nbsp;New user</h1>
            <h1 class="title is-pulled-right"><i class="fas fa-user-plus"></i></h1>
          </div>
          <br>
          <div class="box">
            <ValidationObserver ref="observer">
              <BInputWithValidation rules="required|email" type="email" label="Email" v-model="email"/>

              <BInputWithValidation
                rules="required"
                type="password"
                label="Password"
                vid="password"
                v-model="password"
              />

              <BInputWithValidation
                rules="required|confirmed:password"
                name="Password"
                type="password"
                label="Confirm Password"
                v-model="confirmation"
              />

              <BSelectWithValidation rules="required" label="Role" v-model="role">
                <option value="" disabled>- Please select a role... -</option>
                <option value="reporter">Reporter</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </BSelectWithValidation>

              <br>
              <div class="buttons">
                <button class="button is-success" @click="validateForm">
                  <span class="icon is-small">
                    <i class="fas fa-check"></i>
                  </span>
                  <span>Submit</span>
                </button>
                
                <button class="button" @click="resetForm">
                  <span class="icon is-small">
                    <i class="fas fa-redo"></i>
                  </span>
                  <span>Reset</span>
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BSelectWithValidation from "@/components/inputs/BSelectWithValidation";
import BInputWithValidation from "@/components/inputs/BInputWithValidation";
// import BCheckboxesWithValidation from "@/components/inputs/BCheckboxesWithValidation";

export default {
  data() {
    return {
      email: "",
      password: "",
      confirmation: "",
      role: "",
      choices: []
    }
  },
  components: {
    ValidationObserver,
    BSelectWithValidation,
    BInputWithValidation,
    // BCheckboxesWithValidation
  },
  methods: {
    validateForm() {
      this.startLoading()
      this.$refs.observer.validate()
      .then(success => {
        if (success) {
          this.showSuccessNotification('Form is valid!')
          this.submit()
        } else {
          this.showErrorNotification('Form is invalid!')
        }
      }).catch((err) => {
        this.showErrorNotification('An unexpected error occurred. Please try again later.')
        console.error(err)
      }).finally(() => {
        this.stopLoading()
      })
    },
    submit() {
      this.startLoading()
      this.$http.post('/user/new',{
        email: this.email,
        password: this.password,
        role: this.role
      }).then((response) => {
        console.log(response)
        this.showSuccessNotification('User created successfully!')
        this.$router.push({name: 'users'})
      }).catch((err) => {
        this.showErrorNotification('An unexpected error occurred. Please try again later.')
        console.error(err)
      }).finally(() => {
        this.stopLoading()
      })
    },
    resetForm() {
      this.email = "";
      this.password = "";
      this.confirmation = "";
      this.role = "";
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    }
  }
}

</script>