<template>
  <div class="my-6">
    <div class="columns">
      <div class="column is-6">
        <h1 class="title is-4"><i class="fas fa-arrow-up has-text-success"></i>&nbsp;Round # with highest entries</h1>
        <div class="box">

          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Round #</p>
                <p class="title">{{ roundWithHighestEntries.roundNumber }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading"><i class="fas fa-users"></i>&nbsp;Entries</p>
                <p class="title">{{ roundWithHighestEntries.numEntrants }}</p>
              </div>
            </div>
          </nav>
          <table class="table is-narrow is-bordered is-fullwidth">
            <tbody>
              <tr>
                <th>Start timestamp</th>
                <td>{{ roundWithHighestEntries.startTimestamp }} <span class="is-size-7 has-text-grey">(Asia/Manila)</span></td>
              </tr>
              <tr>
                <th>End timestamp</th>
                <td>{{ roundWithHighestEntries.endTimestamp }} <span class="is-size-7 has-text-grey">(Asia/Manila)</span></td>
              </tr>
              <tr>
                <th>Tournament Instance(s)</th>
                <td>
                  <ul>
                    <li v-for="instance in roundWithHighestEntries.instances" :key="`tournament-${roundWithHighestEntries.tournamentId}-instance-${instance}`">
                      {{ instance }}
                      <router-link :to="{ name: 'reports-tournaments-instances-overview', params: { idTournament: roundWithHighestEntries.tournamentId, idInstance: instance }}">
                        <i class="fas fa-arrow-right fa-lg fa-fw"></i>
                      </router-link>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h1 class="title is-4"><i class="fas fa-arrow-down has-text-danger"></i>&nbsp;Round # with lowest entries</h1>
        <div class="box">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Round #</p>
                <p class="title">{{ roundWithLowestEntries.roundNumber }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading"><i class="fas fa-users"></i>&nbsp;Entries</p>
                <p class="title">{{ roundWithLowestEntries.numEntrants }}</p>
              </div>
            </div>
          </nav>
          <table class="table is-narrow is-bordered is-fullwidth">
            <tbody>
              <tr>
                <th>Start timestamp</th>
                <td>{{ roundWithLowestEntries.startTimestamp }} <span class="is-size-7 has-text-grey">(Asia/Manila)</span></td>
              </tr>
              <tr>
                <th>End timestamp</th>
                <td>{{ roundWithLowestEntries.endTimestamp }} <span class="is-size-7 has-text-grey">(Asia/Manila)</span></td>
              </tr>
              <tr>
                <th>Tournament Instance(s)</th>
                <td>
                  <ul>
                    <li v-for="instance in roundWithLowestEntries.instances" :key="`tournament-${roundWithLowestEntries.tournamentId}-instance-${instance}`">
                      {{ instance }}
                      <router-link :to="{ name: 'reports-tournaments-instances-overview', params: { idTournament: roundWithLowestEntries.tournamentId, idInstance: instance }}">
                        <i class="fas fa-arrow-right fa-lg fa-fw"></i>
                      </router-link>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h1 class="title is-4"><i class="fas fa-arrow-right has-text-info"></i>&nbsp;Last (or current) round</h1>
        <div class="box">
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Round #</p>
                <p class="title">{{ currentRound.roundNumber }}</p>
              </div>
            </div>
            <div class="level-item has-text-centered">
              <div>
                <p class="heading"><i class="fas fa-users"></i>&nbsp;Entries</p>
                <p class="title">{{ currentRound.numEntrants }}</p>
              </div>
            </div>
          </nav>
          <table class="table is-narrow is-bordered is-fullwidth">
            <tbody>
              <tr>
                <th>Start timestamp</th>
                <td>{{ currentRound.startTimestamp }} <span class="is-size-7 has-text-grey">(Asia/Manila)</span></td>
              </tr>
              <tr>
                <th>End timestamp</th>
                <td>{{ currentRound.endTimestamp }} <span class="is-size-7 has-text-grey">(Asia/Manila)</span></td>
              </tr>
              <tr>
                <th>Tournament Instance(s)</th>
                <td>
                  <ul>
                    <li v-for="instance in currentRound.instances" :key="`tournament-${currentRound.tournamentId}-instance-${instance}`">
                      {{ instance }}
                      <router-link :to="{ name: 'reports-tournaments-instances-overview', params: { idTournament: currentRound.tournamentId, idInstance: instance }}">
                        <i class="fas fa-arrow-right fa-lg fa-fw"></i>
                      </router-link>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="column is-6">
        <h1 class="title is-4"><i class="fas fa-chart-line has-text-primary"></i>&nbsp;Grow Rate in the last 10 rounds</h1>
        <div class="box">
          <p><b>Average entries:</b> {{ avgEntries10Rounds }}</p>
          <p class="mt-2" v-show="isTournamentActive"><i class="fas fa-exclamation-circle has-text-info"></i> Tournament is active, so the last round might still not be complete.<span v-show="last10Rounds.length > 1">&nbsp;(The last round is not being considered in the average)</span></p>
        </div>
        <div class="vertical-aligned">
          <table class="table is-striped is-bordered is-fullwidth">
            <thead>
              <tr>
                <th width="80" class="has-text-centered"><i class="fas fa-arrow-up"></i> / <i class="fas fa-arrow-down"></i></th>
                <th class="has-text-centered">Round #</th>
                <th class="has-text-centered">Entries</th>
                <th class="has-text-centered">Grow Rate</th>
                <th>Instance(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(round, index) in last10Rounds" :key="`round-${round.roundNumber}`">
                <td class="has-text-centered">
                  <i v-if="round.growRate > 0" class="fas fa-arrow-up has-text-success"></i>
                  <i v-else-if="round.growRate < 0" class="fas fa-arrow-down has-text-danger"></i>
                  <i v-else class="fas fa-minus"></i>
                </td>
                <td class="has-text-centered">{{ round.roundNumber }}</td>
                <td class="has-text-centered">{{ round.numEntrants }}</td>
                <td class="has-text-centered">{{ index == last10Rounds.length - 1 ? '...' : round.growRate }}</td>
                <td>
                  <ul>
                    <li v-for="instance in round.instances" :key="`tournament-${round.tournamentId}-instance-${instance}`" class="is-size-7">
                      {{ instance }}
                      <router-link :to="{ name: 'reports-tournaments-instances-overview', params: { idTournament: round.tournamentId, idInstance: instance }}">
                        <i class="fas fa-arrow-right fa-lg fa-fw"></i>
                      </router-link>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

  </div>
  </template>

<script>
export default {
  name: 'ReportStats',
  props: {
    chartData: {
      // an array with [{tournamentId, roundNumber, numEntrants}...]
      type: Array,
      required: true
    },
    isTournamentActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      roundWithHighestEntries: null,
      roundWithLowestEntries: null,
      currentRound: null,
      last10Rounds: null,
      avgEntries10Rounds: null,
      avgGrowRate10Rounds: null,
      stdDeviation: null,
    };
  },
  beforeMount() {
    const roundsData = {}
    
    // each key is a round number
    // there might be multiple entries for the same round number
    // for that we need to accumulate the number of entrants
    // and make an array of instances that have that round number

    this.chartData.forEach((round) => {
      if (round.roundNumber in roundsData) {
        roundsData[round.roundNumber].tournamentId += round.tournamentId;
        roundsData[round.roundNumber].instances.push(round.instanceId);
        roundsData[round.roundNumber].roundNumber += round.roundNumber;
        roundsData[round.roundNumber].numEntrants += round.numEntrants;
        roundsData[round.roundNumber].startTimestamp = round.startTimestamp;
        roundsData[round.roundNumber].endTimestamp = round.endTimestamp;
      } else {
        roundsData[round.roundNumber] = {
          tournamentId: round.tournamentId,
          instances: [round.instanceId],
          roundNumber: round.roundNumber,
          numEntrants: round.numEntrants,
          startTimestamp: round.startTimestamp,
          endTimestamp: round.endTimestamp
        }
      }
    });

    console.log(roundsData)

    const rounds = Object.keys(roundsData).sort((a, b) => a - b);

    console.log(rounds)

    // find the round with the highest number of entries
    this.roundWithHighestEntries = Object.values(roundsData).reduce((prev, current) => (prev.numEntrants > current.numEntrants) ? prev : current)

    // find the round with the lowest number of entries
    if(rounds.length > 1){
      if (this.isTournamentActive) {
        // this.roundWithLowestEntries = this.chartData.slice(0, -1).reduce((prev, current) => (prev.numEntrants < current.numEntrants) ? prev : current)

       // if the tournament is active, dont use the current round
        this.roundWithLowestEntries = Object.values(roundsData).slice(0, -1).reduce((prev, current) => (prev.numEntrants < current.numEntrants) ? prev : current)
      } else {
        // this.roundWithLowestEntries = this.chartData.reduce((prev, current) => (prev.numEntrants < current.numEntrants) ? prev : current)
        this.roundWithLowestEntries = Object.values(roundsData).reduce((prev, current) => (prev.numEntrants < current.numEntrants) ? prev : current)
      }
    } else {
      this.roundWithLowestEntries = rounds[0];
    }
    // find the current round
    const currentRoundKey = rounds[rounds.length - 1]
    this.currentRound = roundsData[currentRoundKey]
    
    // 
    if(rounds.length > 10) {
      const last10RoundsKeys = rounds.slice(-10)
      this.last10Rounds = last10RoundsKeys.map(key => roundsData[key])
    } else {
      // if there are less than 10 rounds, just use all of them
      this.last10Rounds = rounds.map(key => roundsData[key])
    }
    // calculate the grow rate for the last 10 rounds
    this.last10Rounds.forEach((round, index) => {
      if (index == 0) {
        round.growRate = 0;
      } else {
        round.growRate = round.numEntrants - this.last10Rounds[index - 1].numEntrants;
      }
    })
    // reverse the array so the last round is the first one
    this.last10Rounds.reverse();
    // calculate the average entries for the last 10 rounds
    // if the tournament is active, dont use the current round
    if(this.last10Rounds.length == 1){
      this.avgEntries10Rounds = this.last10Rounds[0].numEntrants;
    } else {
      if (this.isTournamentActive) {
        this.avgEntries10Rounds = this.last10Rounds.slice(0, -1).reduce((prev, current) => prev + current.numEntrants, 0) / this.last10Rounds.slice(0, -1).length;
      } else {
        this.avgEntries10Rounds = this.last10Rounds.reduce((prev, current) => prev + current.numEntrants, 0) / this.last10Rounds.length;
      }
    }
      this.avgEntries10Rounds = this.avgEntries10Rounds.toFixed(2);
  },
  mounted() {

  },
  methods: {
    
  },
  computed: {
  }
};

</script>