<template>
  <div>
    <b-navbar type="is-dark">
      <template #brand>
        <b-navbar-item tag="div">
          <div class="tag is-primary">
            Tournaments API
          </div>
        </b-navbar-item>
      </template>
      <template #start v-if="loggedUser">
        <b-navbar-item tag="router-link" :to="{ name: 'home' }">
          <i class="fas fa-home fa-fw fa-lg"></i>&nbsp;Home
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'users' }"  v-if="userRole == 'admin'">
          <i class="fas fa-users fa-fw fa-lg"></i>&nbsp;Users
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'tournaments'}" v-if="['admin', 'user'].includes(userRole)">
          <i class="fas fa-trophy fa-fw fa-lg"></i>&nbsp;Tournaments
        </b-navbar-item>
        <b-navbar-dropdown>
          <template #label>
            <i class="fas fa-line-chart fa-fw fa-lg"></i>&nbsp;Tournaments Reports
          </template>
          <b-navbar-item tag="router-link" :to="{ name: 'reports-tournaments-engagement'}" v-if="['admin', 'reporter','user'].includes(userRole)">
            Engagement
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: 'reports-tournaments-currency-spent'}" v-if="['admin', 'reporter','user'].includes(userRole)">
            Currency Spent
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: 'reports-tournaments-instances'}" v-if="['admin', 'reporter','user'].includes(userRole)">
            Instances Overview
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
      <template #start v-else>
        <!-- <b-navbar-item tag="router-link" :to="{name: 'reports-tournaments-engagement'}" v-if="['admin', 'reporter'].includes(userRole)>
          <i class="fas fa-file fa-fw fa-lg"></i>&nbsp;Reportes
        </b-navbar-item> -->
      </template>
      <template #end>
        <b-navbar-item tag="router-link" :to="{ name: 'login' }" v-if="!isLogged">
          <i class="fas fa-sign-in fa-fw fa-lg"></i>&nbsp;Log in
        </b-navbar-item>
        <b-navbar-item @click="logout" v-else>
          <i class="fas fa-sign-out fa-lg fa-fw fa-lg"></i>&nbsp;Log out
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'TheNavbar',
  data() {
    return {
    };
  },
  methods: {
    logout() {
      this.$http.get('/user/logout')
      .then(() => {
        this.$store.dispatch('logout');
      })
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters.isLogged;
    },
    userRole(){
      return this.$store.getters.getLoggedUserRole;
    }
  }
};
</script>