import store from "@/store";

var baseUtils = {
  methods: {
    startLoading() {
      store.commit('setLoading', true);
    },
    stopLoading() {
      store.commit('setLoading', false);
    },
    showErrorNotification: function (msg) {
      this.$buefy.snackbar.open({
          message: `⚠️&nbsp;` + msg,
          type: 'is-warning',
          // pauseOnHover: true,
          duration: 5000,
          position: 'is-bottom-left'
          
      })
    },
    showSuccessNotification: function (msg) {
      this.$buefy.snackbar.open({
          message: `✅&nbsp;` + msg,
          // pauseOnHover: true,
          duration: 5000,
          position: 'is-bottom-left',
          type: 'is-white'
      })
    },
    getValue(value) {
      if (value === undefined) return null;
      if (value === null) return null;
      return value;
    },
    isOptional(value) {
      if (value === null || value === '') {
        return null;
      }
      if (typeof value !== 'undefined' && value.length === 0) {
        return [];
      } 
      return value;
    },
    isInputEmpty(value) {
      if (value === null || value === '') {
        return true;
      }
      if (typeof value !== 'undefined' && value.length === 0) {
        return true;
      }
      return false;
    },
    isObject(val) {
      return val !== null && typeof val === 'object' && Array.isArray(val) === false;
    },
    isObjectEmpty(obj) {
      for(var key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if(obj.hasOwnProperty(key)){
          return false;
        }
      }
      return true;
    },
    getString(name) {
      // if the string starts with * just return the same string
      if (name.startsWith('*')) {
        return name;
      }
      if(store.state.strings === undefined) {
        console.error('store.strings is not defined');
        return name;
      }
      // if the string doesnt start with * find the name in the strings array
      const string = store.state.strings.find(string => string.name === name);
      // if the string is found return the value
      if (string) {
        return string.value;
      }
      // if the string is not found return the name
      return '???????????';
    },
    stringHasDigits(str) {
      return /\d/.test(str);
    },
    formatNumber(number) {
      return new Intl.NumberFormat({ style: 'decimal' }).format(number)
    },
    reloadStringsPromise() {
      return this.$http.get('/stringtable/list')
    },
    reloadStrings() {
      this.$http.get('/stringtable/list')
        .then(response => {
          store.commit('setStrings', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    loggedUser() {
      return store.state.loggedUser;
    },
  },
};



export default baseUtils