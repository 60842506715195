<template>
  <div class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-6">
          <div class="is-clearfix">
            <h1 class="title is-pulled-left">Users</h1>
            <div class="is-pulled-right">
              <div class="buttons">
                <button @click="getUsers" class="button is-light">
                  <i class="fas fa-sync"></i>
                </button>
                <router-link class="button is-primary" :to="{ name: 'users-new' }">
                  <i class="fas fa-user-plus"></i>
                </router-link>
              </div>
            </div>
          </div>
          <!-- table -->
          <b-table :data="users" :loading="isLoading" :paginated="true" :per-page="10">
            <b-table-column field="email" sortable label="Email" v-slot="props">
              {{ props.row.email }}
            </b-table-column>
            <b-table-column field="role" label="Role" sortable v-slot="props">
              <i class="fas fa-fw" :class="{'fa-shield': props.row.role == 'admin', 'fa-user': props.row.role == 'user', 'fa-line-chart': props.row.role == 'reporter'}"></i>
              {{ props.row.role && props.row.role.toUpperCase() }}
            </b-table-column>
            <b-table-column label="Action" centered v-slot="props">
                <!-- <router-link class="has-text-primary" :to="{ name: 'users-edit', params: { id: props.row.id } }">
                  <span class="icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </router-link> -->
              <a class="has-text-danger" @click="confirmDeleteUser(props.row)">
                <span class="icon">
                  <i class="fas fa-trash"></i>
                </span>
              </a>
            </b-table-column>
            <template #empty>
              <p class="is-size-7 has-text-centered py-5 is-family-code">No users</p>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// User has email, and role
export default {
  data() {
    return {
      users: [],
      isLoading: false
    };
  },
  methods: {
    getUsers() {
      this.isLoading = true;
      this.$http.get('/user/list').then(response => {
        this.users = response.data;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    confirmDeleteUser(user) {
      this.$buefy.dialog.confirm({
        title: 'Delete User',
        message: `Are you sure you want to delete user <b>${user.email}</b>?`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteUser(user.id)
      })
    },
    deleteUser(id) {
      this.$http.post('/user/delete', { id: id })
      .then(() => {
        this.showSuccessNotification('User deleted successfully');
        this.getUsers();
      }).catch(error => {
        this.showErrorNotification('Error deleting user');
        console.error(error)
      })
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>