<template>
  <div id="app">
    <Navbar />
    <!-- <div class="notification is-light has-text-centered is-radiusless is-marginless is-800 is-size-5">
      {{$route.meta.navbarName}}
    </div>  -->
    <router-view />
    <b-loading ref="loader" :active="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      checkLoginInterval: null
    };
  },
  mounted() {
    // check Login every 5 minutes
    // this.checkLoginInterval = setInterval(this.checkLogin,
    //   1000 * 60 * 5);
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    loggedUser() {
      return this.$store.state.loggedUser;
    }
  }
};
</script>


<style lang="scss">
@import "./assets/scss/app.scss";
</style>
