var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('h2',{staticClass:"subtitle is-6 is-italic has-text-grey"},[_vm._v("Tournament ID: "+_vm._s(_vm.idTournament)+" / Tournament Instance: "+_vm._s(_vm.idInstance))]),(_vm.isLoading)?_c('div',{staticClass:"notification"},[_vm._m(1)]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"notification is-warning"},[_vm._m(2),_c('pre',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),(_vm.tournamentInstance)?_c('div',[_c('hr'),_c('InstanceData',{key:`tournament-${_vm.tournamentInstance.instanceId}-data`,attrs:{"tournamentInstance":_vm.tournamentInstance}}),_c('hr'),_vm._m(3),(_vm.notFinished)?_c('div',[_vm._m(4)]):_vm._e(),_c('InstanceLeaderboard',{key:`tournament-${_vm.tournamentInstance.instanceId}-leaderboard`,attrs:{"idTournament":_vm.idTournament,"idInstance":_vm.idInstance,"tournamentInstance":_vm.tournamentInstance}}),_c('hr'),_vm._m(5),(_vm.notFinished)?_c('div',[_vm._m(6)]):_c('div',[_c('InstanceNotifications',{key:`tournament-${_vm.tournamentInstance.instanceId}-standings`,attrs:{"idTournament":_vm.idTournament,"idInstance":_vm.idInstance,"tournamentInstance":_vm.tournamentInstance}})],1)],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"title is-3"},[_c('i',{staticClass:"fas fa-trophy fa-fw"}),_c('i',{staticClass:"fas fa-table fa-fw"}),_vm._v(" Tournament Instance - Overview")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-sync fa-spin fa-lg fa-fw"}),_vm._v(" Loading tournament instance...")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-exclamation-triangle fa-lg"}),_vm._v(" There was an error loading the tournament instance. Please try again later.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"title is-4"},[_c('i',{staticClass:"fas fa-table"}),_vm._v(" Leaderboard")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-exclamation-triangle fa-lg"}),_vm._v(" Instance is currently running. No winners have been awarded yet. This table might constantly change.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"title is-4"},[_c('i',{staticClass:"fas fa-envelope"}),_vm._v(" Notifications sent")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-exclamation-triangle fa-lg"}),_vm._v(" There are no winners yet for this tournament instance.")])
}]

export { render, staticRenderFns }