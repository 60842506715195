<template>
  <div class="card">
    <div class="card-content">
      <div v-if="isLoading" class="has-text-centered">
        <p><i class="fas fa-sync fa-spin fa-lg"></i></p>
        <p>Loading user data...</p>
      </div>
      <div v-else>
        <div class="media" v-if="user">
          <div class="media-left">
            <figure class="image is-128x128">
              <img
                :src="user.profile_picture"
                v-if="user && user.profile_picture"
                class="is-rounded"
                style="border-radius: 8px"
                alt="User avatar"
              />
              <div class="is-rounded" style="width: 128px; height: 128px; background-color: #CACACA; border-radius: 8px" v-else></div>

            </figure>
          </div>
          <div class="media-content">
            <p class="title is-3">{{ user.name }}</p>
            <p class="subtitle is-6 is-family-monospace has-text-grey">ID: {{ user.id }}</p>
            <table class="table is-fullwidth is-bordered is-narrow">
              <tbody>
                <tr>
                  <th>Last Login</th>
                  <td>{{ user.last_login }} (Asia/Manila)</td>
                </tr>
                <tr>
                  <th>Blacklisted</th>
                  <td>{{ user.blacklisted ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <th>Cheater</th>
                  <td>{{ user.cheater ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <th>Cheater Created At</th>
                  <td>
                    <span v-if="user.cheater_created_at">{{ user.cheater_created_at }}</span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                <tr>
                  <th>Login Type</th>
                  <td>{{ user.login }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="errorMessage">
        <div class="notification is-danger">
          <p><i class="fas fa-exclamation-triangle fa-lg"></i> {{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalUserData',
  props: {
    userId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      user: null,
      isLoading: true,
      errorMessage: ''
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      console.log('fetching data')
      this.isLoading = true;
      this.$http.get(`/player/${this.userId}`)
      .then(response => {
        this.user = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        const errorMessage = 'There was an error loading the user data. Please try again later.';
        this.errorMessage = errorMessage
        this.showErrorNotification(errorMessage);
        this.isLoading = false;
        console.error(error);
      });
    }
  }
}
</script>