import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import UserList from '../views/users/List.vue'
import UserNew from '../views/users/New.vue'
import TournamentList from '../views/tournaments/List.vue'
import TournamentNew from '../views/tournaments/New.vue'
import TournamentEdit from '../views/tournaments/Edit.vue'
import ReportsTournamentsEngagement from '../views/reports/tournaments/Engagement.vue'
import ReportsTournamentsCurrencySpent from '../views/reports/tournaments/CurrencySpent.vue'
import ReportsTournamentsInstances from '../views/reports/tournaments/Instances.vue'
import ReportsTournamenstInstancesOverview from '../views/reports/tournaments/instances/Overview.vue'
import Forbidden from '../views/Forbidden.vue'
import store from '../store'

// import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user']
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UserList,
    meta: {
      requiresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/users/new',
    name: 'users-new',
    component: UserNew,
    meta: {
      requiresAuth: true,
      roles: ['admin']
    }
  },
  {
    path: '/tournaments',
    name: 'tournaments',
    component: TournamentList,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user']
    }
  },
  {
    path: '/tournaments/new',
    name: 'tournaments-new',
    component: TournamentNew,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user']
    }
  },
  {
    path: '/tournaments/:id',
    name: 'tournaments-edit',
    component: TournamentEdit,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'user']
    }
  },
  {
    path: '/reports/tournaments/engagement',
    name: 'reports-tournaments-engagement',
    component: ReportsTournamentsEngagement,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'reporter','user']
    }
  },
  {
    path: '/reports/tournaments/currency-spent',
    name: 'reports-tournaments-currency-spent',
    component: ReportsTournamentsCurrencySpent,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'reporter','user']
    }
  },
  {
    path: '/reports/tournaments/instances',
    name: 'reports-tournaments-instances',
    component: ReportsTournamentsInstances,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'reporter','user']
    }
  },
  {
    path: '/reports/tournaments/:idTournament/instances/:idInstance/overview',
    name: 'reports-tournaments-instances-overview',
    component: ReportsTournamenstInstancesOverview,
    meta: {
      requiresAuth: true,
      roles: ['admin', 'reporter','user']
    }
  },
  {
    // catch all
    path: '*',
    name: 'not-found',
    component: Forbidden
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

// check if the route requires authentication and if the user has then role to access it
router.beforeEach((to, from, next) => {
  // check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // check if the user is logged in
    if (store.getters.isLogged) {
      // check if the user has the role to access the route
      if (to.meta.roles.includes(store.getters.getLoggedUserRole)) {
        next();
      } else {
        next({ name: 'not-found' });
      }
    } else {
      next({ name: 'login' });
    }
  } else {
    if(to.name === 'login' && store.getters.isLogged) {
      next({ name: 'home' });
    } else {
      next();
    }
  }
})


export default router
