<template>
  <v-chart class="chart-rounded" :option="chartOptions" />
</template>

<script>
  import { use } from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { LineChart } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent
  } from "echarts/components";
  import VChart, { THEME_KEY } from "vue-echarts";

  use([
    CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent
  ]);
  
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      chartData: {
        type: Object,
        required: true
      },
      chartMeta: {
        type: Object,
        required: true
      }
    },
    components: {
      VChart
    },
    provide: {
      [THEME_KEY]: "dark"
    },
    computed: {
      chartOptions() {
        return {
          backgroundColor: '#2c343c',
          title: {
            text: this.title || 'Chart',
            textStyle: {
              color: '#fff'
            },
            top: 15,
            left: '4%'
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {
                show: true,
                title: 'Guardar'
              }
            }
          },
          legend: {
            data: ['Coins', 'Diamonds'],
            right: '7%',
            top: 15
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              snap: true,
              label: {
                backgroundColor: '#42a883'
              }
            },
            formatter: (params) => {
              const roundMeta = this.chartMeta[params[0].axisValue];
              let text = `<div class="has-text-black">`
              text += `<p class="is-size-6 mb-1 has-text-weight-bold">Round # ${params[0].axisValue}</p>`
              text += `<p class="is-size-7">Instances:</p>`
              text += `<p class="is-family-monospace instances-list mb-2">${roundMeta.instancesId.join('<br>')}</p>`
              if(roundMeta.merged) {
                text += `<p class="is-size-7"><i class="fas fa-exclamation-triangle has-text-danger fa-fw"></i>&nbsp;Multiples instances in the same week</p>` 
              }
              if(roundMeta.differentEntryCosts) {
                text += `<p class="is-size-7"><i class="fas fa-exclamation-triangle has-text-danger fa-fw"></i>&nbsp;Mixed entry costs</p>`
              }
              if(roundMeta.mixedCurrencies) {
                text += `<p class="is-size-7"><i class="fas fa-exclamation-triangle has-text-danger fa-fw"></i>&nbsp;Mixed currencies</p>`
              }
              // print the start and end datetime of the round
              text += `<p class="is-size-6 mt-2"><i class="fas fa-calendar-alt fa-fw"></i>&nbsp;<span class="is-size-6">${roundMeta.startDatetime}</span></p>`
              text += `<p class="is-size-6"><i class="fas fa-calendar-alt fa-fw"></i>&nbsp;<span class="is-size-6">${roundMeta.endDatetime}</span></p>`
              text += `<p class="is-size-6 mt-3"><i class="fas fa-right-to-bracket fa-fw"></i>&nbsp;${roundMeta.entriesPaid} entries paid</p>`
              if(roundMeta.differentEntryCosts) {
                text += `<p class="is-size-6"><i class="fas fa-ticket fa-fw"></i>&nbsp;Entry cost changed...</p>` 
              } else {
                text += `<p class="is-size-6"><i class="fas fa-ticket fa-fw"></i>&nbsp;${roundMeta.entryCost} entry cost</p>` 
              }
              text += `<p class="is-size-6"><i class="fas fa-coins fa-fw"></i>&nbsp;${roundMeta.coins} coins spent</p>`
              text += `<p class="is-size-6"><i class="fas fa-gem fa-fw"></i>&nbsp;${roundMeta.diamonds} diamonds spent</p>`
              text += `</div>`
              return text;
            }
          },
          grid: {
            left: '4%',
            right: '7%',
            bottom: '80',
            top: '15%',
            containLabel: true
          },
          xAxis: [
          {
              name: 'Round #',
              type: 'category',
              data: this.chartData.serieX.rounds,
              nameLocation: 'center',
              nameGap: 30,
              nameTextStyle: {
                fontWeight: 800
              },
              splitLine: {
                show: true
              }
            },
            {
              name: 'Date Round',
              type: 'category',
              data: this.chartData.serieX.dates,
              nameLocation: 'center',
              nameGap: 43,
              nameTextStyle: {
                fontWeight: 800
              },
              offset: 20,
              splitLine: {
                show: true
              },
              axisLabel: {
                formatter: (value) => {
                  // ValueStr is a date un YYYY-MM-DD HH:MM:SS format, between date and time add a new line
                  if(value.length > 10) {
                    return `${value.slice(0, 10)}\n${value.slice(11)}`
                  } else {
                    return value
                  }
                }
              }
            }
          ],
          // yAxis: {
          //   name: 'Amount Spent',
          //   type: 'value',
          //   splitArea: {
          //     show: true
          //   },
          //   nameLocation: 'center',
          //   nameGap: -20,
          //   axisLabel:{
          //     // inside: true,
          //     // verticalAlign: 'middle',
          //     nameGap: 10
          //   }
          // },
          yAxis: [
            {
              name: 'Diamond Spent',
              type: 'value',
              splitArea: {
                show: true
              },
              nameLocation: 'center',
              nameGap: -20,
              axisLabel:{
                // inside: true,
                // verticalAlign: 'middle',
                nameGap: 10
              }
            },
            {
              name: 'Coins Spent',
              type: 'value',
              splitArea: {
                show: true
              },
              nameLocation: 'center',
              nameGap: -20,
              axisLabel:{
                // inside: true,
                // verticalAlign: 'middle',
                nameGap: 10
              }
            }
          ],
          dataZoom: [
            {
              type: 'inside',
            },
            {
              type: 'slider',
              xAxisIndex: 1,
              // yAxisIndex: [0,1],
              bottom: 20,
              labelFormatter: (value, valueStr) => {
                // ValueStr is a date un YYYY-MM-DD HH:MM:SS format, between date and time add a new line
                if(valueStr.length > 10) {
                  return `${valueStr.slice(0, 10)}\n${valueStr.slice(11)}`
                } else {
                  return valueStr
                }
              }
            }
          ],
          series: [
            {
              name: "Diamonds",
              data: this.chartData.serieY.diamonds,
              type: 'line',
              yAxisIndex: 0,
              symbolSize: 6,
              symbol: 'circle',
              color: '#fff',
              lineStyle: {
                color: '#00A0FF'
              }
            },
            {
              name: "Coins",
              data: this.chartData.serieY.coins,
              type: 'line',
              yAxisIndex: 1,
              symbolSize: 6,
              symbol: 'circle',
              color: '#fff',
              lineStyle: {
                color: '#ffcc00'
              }
            },
          ]
        };
      }
    }
  }
</script>
