<template>
  <div>
    <h1 class="title is-3 mt-5">
     Tournament Instances
    </h1>
    <h5 class="subtitle is-6 is-italic has-text-grey">
      Do note that there might be multiple instances per round for adverse reasons.
    </h5>
    <b-table class="vertical-aligned" :data="tableData" hoverable bordered narrowed striped>
      <b-table-column field="round" centered label="Round" v-slot="props">
        {{ props.row.round }}  
      </b-table-column>
      <b-table-column field="key" label="Instance ID" v-slot="props">
        <p class="is-size-7 is-family-monospace">{{ props.row.key }} <router-link :to="{ name: 'reports-tournaments-instances-overview', params: { idTournament: tournamentId, idInstance: props.row.key }}"><i class="fas fa-arrow-right fa-lg fa-fw"></i></router-link></p>
      </b-table-column>
      <b-table-column field="entryCost" label="Entry Cost" centered v-slot="props">
        <p>{{ props.row.entryCost }}</p>
        <p class="is-size-7">{{ props.row.entryCurrencyLabel }}</p>
      </b-table-column>
      <b-table-column field="entriesPaid" label="Entries Paid" centered v-slot="props">
        <p>{{ formatLargeNumber(props.row.entriesPaid || 0) }}</p>
      </b-table-column>  
      <b-table-column label="Grow Rate" centered v-slot="props">
        <p :class="props.row.growEntriesPaid > 0 ? 'has-text-success' : props.row.growEntriesPaid < 0 ? 'has-text-danger' : ''" >
          {{formatLargeNumber(props.row.growEntriesPaid)}}
          &nbsp;<i v-if="props.row.growEntriesPaid > 0" class="fas fa-arrow-up fa-lg has-text-success"></i>
          <i v-else-if="props.row.growEntriesPaid < 0" class="fas fa-arrow-down fa-lg has-text-danger"></i>
          <i v-else class="fas fa-minus"></i>
      </p>
      </b-table-column>
      <b-table-column label="Round Date" centered v-slot="props">
        <p class="is-size-7">{{ props.row.startDatetime }}</p>
        <p class="is-size-7">{{ props.row.endDatetime }}</p>
      </b-table-column>
      <b-table-column field="coins" label="Coins" centered v-slot="props">
        {{ formatLargeNumber(props.row.coins) }}
      </b-table-column>
      <b-table-column field="diamonds" label="Diamonds" centered v-slot="props">
        {{ formatLargeNumber(props.row.diamonds) }}
      </b-table-column>
      <b-table-column label="Accumulated" v-slot="props">
        <p>💰 {{ formatLargeNumber(props.row.accumulatedCoins) }}</p>
        <p>💎 {{ formatLargeNumber(props.row.accumulatedDiamonds) }}</p>
      </b-table-column>
    </b-table>
  </div>

</template>

<script>
export default {
  name: 'InstancesTable',
  props:{
    instancesTableData: {
      type: Array,
      required: true
    },
    tournamentName: {
      type: String,
      required: true
    },
    tournamentId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      tableData: this.instancesTableData
    }
  },
  methods: {
    formatLargeNumberCompact(number) {
      return new Intl.NumberFormat('en', {
        style: "decimal",
        notation: "compact",
        compactDisplay: "short",
        maximumSignificantDigits: 3
      }).format(number);
    },
    formatLargeNumber(number) {
      return new Intl.NumberFormat('en', {style: "decimal"}).format(number);
    },
  }
}

</script>

<style lang="scss">
.vertical-aligned{
  .table td {
      vertical-align: middle;
    }
}
</style>
```